import { FunctionComponent, PropsWithChildren } from "react";

import { CenteredBox, CenteredLargeSpinner } from "@bps/fluent-ui";
import { AuthorisationError, NotFoundError } from "@bps/http-client";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { ErrorAlert } from "@ui-components/Alert.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { useAuthorisationContext } from "../context/AuthorisationContext.tsx";
import { AuthorizationErrorBox } from "./AuthorizationErrorBox.tsx";

export const SessionAuthentication: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const { login } = useAuthorisationContext();
  const { core, userExperience } = useStores();

  const fetchData = async (root: RootStore) => {
    if (!core.isLoggedIn) {
      await login();
    }

    await Promise.all([
      core.loadOrgUnits(),
      userExperience.getCurrentUserSetting(),
      userExperience.loadTenantSetting(),
      userExperience.loadLocationBasedOrgUnitSetting(),
      root.preloadRefData()
    ]);
  };

  return (
    <DataFetcher
      fetch={fetchData}
      fallback={<CenteredLargeSpinner />}
      renderError={error => {
        if (error instanceof NotFoundError) {
          return <ErrorAlert error={error.message} />;
        } else if (error instanceof AuthorisationError) {
          return <AuthorizationErrorBox />;
        }

        // Note that navigator.onLine true doesn't necessarily mean that the internet connection works.
        // If the application is meant to work offline, update the logic here accordingly.
        const errorMessage = !navigator.onLine
          ? "Could not resolve endpoints. Please check network and try again"
          : error;

        return (
          <CenteredBox>
            <ErrorAlert error={errorMessage} showReloadLink />
          </CenteredBox>
        );
      }}
    >
      {() => children}
    </DataFetcher>
  );
};
