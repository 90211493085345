import { observer } from "mobx-react-lite";
import { createElement, FunctionComponent } from "react";
import { Outlet, Navigate as RedirectRoute } from "react-router-dom";

import { AuthProps } from "@shared-types/routing/auth-props.interface.tsx";
import { useRouteDefinitionsFilter } from "@stores/router/hooks/useRouteDefinitionsFilter.ts";

export const AuthLayout: FunctionComponent<AuthProps> = observer(props => {
  const routeFilter = useRouteDefinitionsFilter();

  const { unauthorised, redirect, fallbackRoutes } = props;

  const isRouteAuthorised = routeFilter(props);
  const getRedirect = () => {
    if (redirect) {
      return redirect;
    }
    if (fallbackRoutes?.length) {
      const fallbackRoute = fallbackRoutes.find(route => routeFilter(route));
      return fallbackRoute?.path?.pattern;
    }
    return undefined;
  };

  //check auth
  if (!isRouteAuthorised) {
    const redirect = getRedirect();
    if (redirect) {
      return <RedirectRoute to={redirect} replace />;
    }
    if (unauthorised) {
      return createElement(unauthorised);
    }
  }

  return <Outlet />;
});
