import { FunctionComponent, memo } from "react";

import { CenteredBox, FontIcon, FontSizes, useTheme } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { useAuthorisationContext } from "../context/AuthorisationContext.tsx";

export const AuthorizationErrorBox: FunctionComponent = memo(() => {
  const { core } = useStores();
  const theme = useTheme();
  const { login } = useAuthorisationContext();

  const onLoginClick = async (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
    await login();
  };
  return (
    <CenteredBox>
      <div>
        <FontIcon
          iconName="BpLogo"
          styles={{
            root: { color: theme.palette.black, fontSize: FontSizes.size24 }
          }}
        />
        <h2>403</h2>
        <p>
          You do not have appropriate permissions to access this page. <br />
          Check the URL and your user permissions before trying again.
        </p>
        {core.accountInfo && (
          <p>You are currently logged in as {core.accountInfo.username}.</p>
        )}
        <p>
          Alternatively,{" "}
          <Navigate onClick={onLoginClick}>login as a different user.</Navigate>
        </p>
      </div>
    </CenteredBox>
  );
});
