import { BroadcastChannel, OnMessageHandler } from "broadcast-channel";
import { action, observable, reaction } from "mobx";

import {
  getAccessToken,
  getActiveAccount,
  login,
  logout
} from "@bps/http-client";
import { StorageProperties } from "@libs/constants/storage-properties.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import * as serviceWorker from "../../../registerServiceWorker.tsx";
import { UserActivityTracker } from "./UserActivityTracker.ts";
import { UserSessionTimeTracker } from "./UserSessionTimeTracker.ts";

const BROADCAST_CHANNEL_NAME = "bp-app-data";

enum BroadcastChannelMessages {
  logout = 1,
  lock = 2,
  unlock = 3
}

export class AuthorisationHelper {
  constructor(
    private core: CoreStore,
    private userExperience: UserExperienceStore
  ) {
    // persist the locked state in sessionStorage
    reaction(
      () => this.locked,
      locked => {
        if (locked) {
          localStorage.setItem(StorageProperties.locked, "true");
        } else {
          localStorage.removeItem(StorageProperties.locked);
        }
      }
    );

    // the reaction makes logout process to be async
    // in willLogout is used in useScreenBlocker to prevent browsers prompt appearing when redirect to login page
    reaction(
      () => this.willLogout,
      async willLogout => {
        if (willLogout) {
          sessionStorage.removeItem(StorageProperties.clinicalRecordsState);
          localStorage.removeItem(StorageProperties.locked);
          serviceWorker.unregister();
          await logout();
        }
      }
    );
    this.userSessionTimeTracker = new UserSessionTimeTracker(this.core);

    this.userActivityTracker = new UserActivityTracker(
      async () => {
        try {
          const token = await getAccessToken();
          // validate access token and end user sessions
          if (!token) {
            await this.logoutAllClients();
          }
        } catch {
          await this.logoutAllClients();
        }
      },
      async () => {
        await this.setLocked(true);
      },
      this.userExperience
    );
  }

  private channel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

  @observable
  public locked: boolean = !!localStorage.getItem(StorageProperties.locked);

  @observable
  public willLogout: boolean = false;

  @action
  public onBroadcastChannelReceiveEvent: OnMessageHandler<BroadcastChannelMessages> =
    (event: BroadcastChannelMessages) => {
      if (event === BroadcastChannelMessages.logout) {
        this.logout();
      }

      if (event === BroadcastChannelMessages.lock) {
        this.locked = true;
      }

      if (event === BroadcastChannelMessages.unlock) {
        this.locked = false;
      }
    };

  /**
   * Allows to notify all clients (in a browser) to logout
   */
  public logoutAllClients = async () => {
    await this.channel.postMessage(BroadcastChannelMessages.logout);
    // ⚠️⚠️⚠️ the action is for current tab logout, since all other will be served via BroadcastChannelMessages receiver
    localStorage.removeItem(StorageProperties.locked);
    await this.logout();
  };

  public userActivityTracker: UserActivityTracker;
  public userSessionTimeTracker: UserSessionTimeTracker;

  public login = async () => {
    // check and get current account
    const account = await getActiveAccount();

    // if we are not authorises and not login previously, do log in redirect
    if (!account) {
      localStorage.removeItem(StorageProperties.locked);
      await login();
      return;
    }

    // set current account
    this.core.setAccountInfo(account);
    // The current user needs to be loaded before the catalog role to be retrieved.
    await this.core.loadCurrentUser();

    await Promise.all([
      this.core.getCatalogBusinessRoles(),
      this.core.getUserSecGroupMemberships(),
      this.core.getUserSecGroupAccessRequests(),
      this.core.getLicences({ isInactive: false })
    ]);
  };

  @action
  public setLocked = async (value: boolean) => {
    if (
      value &&
      (!this.core.permissions.includes(Permission.InactiveLockScreenAllowed) ||
        !(
          this.userExperience.tenantSecuritySettings
            ?.inactivityTimeoutEnabled ?? true
        ))
    )
      return;

    this.locked = value;
    await this.channel.postMessage(
      value ? BroadcastChannelMessages.lock : BroadcastChannelMessages.unlock
    );
  };

  @action
  public logout = () => {
    // set willLogout as true and resole reaction in constructor
    this.willLogout = true;
  };

  public unsubscribeBroadcastChannel = async () => {
    await this.channel.close();
    await this.channel.removeEventListener(
      "message",
      this.onBroadcastChannelReceiveEvent
    );
  };

  /**
   * Subscribe BroadcastChannel messages to log out from app in all opened browser tabs
   */
  public subscribeBroadcastChannel = () => {
    this.channel.addEventListener(
      "message",
      this.onBroadcastChannelReceiveEvent
    );
  };
}
