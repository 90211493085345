import { useScreenBlocker } from "app-shell/session/hooks/useScreenBlocker.tsx";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { useTheme } from "@bps/fluent-ui";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { useAuthorisationContext } from "../../context/AuthorisationContext.tsx";
import { InactivityPinForm } from "./InactivityPinForm.tsx";
import {
  InactivityPinFormValues,
  PIN_LENGTH
} from "./InactivityPinForm.types.ts";

const InactivityPinDialogBase: FunctionComponent = observer(() => {
  const theme = useTheme();
  const { core, userExperience } = useStores();
  const { setLocked } = useAuthorisationContext();

  useScreenBlocker();

  return (
    <SubmissionFormDialog<InactivityPinFormValues>
      dialogName="Inactivity PIN dialog"
      subscription={{ values: true }}
      onSubmit={async ({ invalidPin, showPin, ...pinValues }, form) => {
        const sortedPinValues = Object.keys(pinValues)
          .sort()
          .map(key => pinValues[key]);

        const pin = sortedPinValues.join("");
        const userSignInName = core.user?.username;
        if (pin.length === PIN_LENGTH && userSignInName !== undefined) {
          const dto = {
            userSignInName,
            pin
          };

          const isValid = await userExperience.verifyPin(dto);

          if (isValid) {
            await setLocked(false);
          }
          form.change(nameof("invalidPin"), !isValid);
        }
      }}
      disablePrompt
      lockAnyRouting
      hideButtons
      styles={{ root: { marginBottom: 64 } }}
      dialogProps={{
        minWidth: 448,
        onDismiss: () => {},
        modalProps: {
          isDarkOverlay: true,
          overlay: {
            styles: {
              root: {
                backgroundColor: theme.palette.neutralTertiary,
                opacity: 0.9
              }
            }
          }
        },
        dialogContentProps: {
          showCloseButton: false
        }
      }}
    >
      {() => <InactivityPinForm />}
    </SubmissionFormDialog>
  );
});

export const InactivityPinDialog: FunctionComponent = observer(() => {
  const { locked } = useAuthorisationContext();

  if (locked) return <InactivityPinDialogBase />;
  return null;
});
