import { createBrowserRouter, RouteObject } from "react-router-dom";

import { RouterStore } from "@stores/router/RouterStore.ts";

//Temporary to keep router store location in sync.
export function syncNavigateWithStore(
  routing: RouterStore,
  routes: RouteObject[]
) {
  const router = createBrowserRouter(routes);
  routing.init(router);
  return router;
}
