import {
  action,
  computed,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
  when
} from "mobx";

import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

export enum TimeOut {
  notShown = 1,
  stage1Warning = 2,
  stage2Warning = 3,
  logOutWarning = 4
}
export class UserSessionTimeTracker {
  private disposer: IReactionDisposer;
  readonly ONE_MIN_IN_MILLISECONDS = 60000;
  readonly TWENTY_FOUR_HOURS_IN_MINUTES = 24 * 60;
  readonly TWENTY_FOUR_HOURS_IN_MILLISECONDS =
    this.TWENTY_FOUR_HOURS_IN_MINUTES * this.ONE_MIN_IN_MILLISECONDS;

  //Stage
  public WARNING_STAGE_1_IN_MILLISECONDS = 30 * this.ONE_MIN_IN_MILLISECONDS; //1800000
  public WARNING_STAGE_2_IN_MILLISECONDS = 15 * this.ONE_MIN_IN_MILLISECONDS; //900000
  public WARNING_STAGE_3_IN_MILLISECONDS = this.ONE_MIN_IN_MILLISECONDS; //60000

  readonly userSessionTime;

  coachMarkSetIntervalId: ReturnType<typeof setInterval>;

  constructor(
    private core: CoreStore,
    private delay?: number
  ) {
    this.userSessionTime = core.hasPermissions(Permission.ReduceMfaTimerAllowed)
      ? this.WARNING_STAGE_2_IN_MILLISECONDS + 5 * this.ONE_MIN_IN_MILLISECONDS
      : this.TWENTY_FOUR_HOURS_IN_MILLISECONDS; // default 24 hours
    this.awaitStartTimer().then(() => {
      this.disposer = reaction(
        () => this.timeOutState,
        () => {
          runInAction(() => {
            this.hasClosedCoachMark = false;
          });
        }
      );
    });
  }

  disposes = () => {
    this.disposer();
    this.clearCoachMarkInterval();
  };

  @computed
  get endSessionTimeLeftInMinutes() {
    if (typeof this.endSessionTimeLeft === "undefined") {
      return undefined;
    }

    return Math.ceil(this.endSessionTimeLeft / this.ONE_MIN_IN_MILLISECONDS);
  }

  get endSessionWarningInMinutes() {
    return (
      Math.ceil(
        this.WARNING_STAGE_3_IN_MILLISECONDS / this.ONE_MIN_IN_MILLISECONDS
      ) - 1
    );
  }

  @computed
  get isReachedTimeOut() {
    return (
      this.timeOutState !== TimeOut.notShown &&
      this.core.hasPermissions(Permission.InactiveLockScreenAllowed)
    );
  }

  @action
  setTimeOutState = (state: TimeOut) => {
    this.timeOutState = state;
  };

  clearCoachMarkInterval = () => {
    clearInterval(this.coachMarkSetIntervalId);
  };

  startTimer = () => {
    this.stateHandler();
    this.coachMarkSetIntervalId = setInterval(() => {
      this.stateHandler();
    }, this.delay ?? 60000);
  };

  async awaitStartTimer() {
    await when(() => !!this.authTime && !!this.endSessionTimeLeft);
    this.startTimer();
  }

  @computed
  get authTime() {
    return this.core.accountInfo?.authTime;
  }

  @computed
  get endSessionTimeLeft() {
    if (!this.authTime) {
      return undefined;
    }

    const logoutDate = this.authTime * 1000 + this.userSessionTime;

    return logoutDate - this.currentTime;
  }

  @observable
  private currentTime = DateTime.now().toMillis();

  @observable
  public timeOutState: TimeOut = TimeOut.notShown;

  @observable
  public hasClosedCoachMark: boolean = false;

  @action
  stateHandler = () => {
    if (!this.endSessionTimeLeft || this.endSessionTimeLeft < 0) {
      return;
    }

    //Handle counting behaviors
    if (this.endSessionTimeLeft <= this.WARNING_STAGE_3_IN_MILLISECONDS) {
      this.setTimeOutState(TimeOut.logOutWarning);
    } else if (
      this.endSessionTimeLeft <= this.WARNING_STAGE_2_IN_MILLISECONDS
    ) {
      this.setTimeOutState(TimeOut.stage2Warning);
    } else if (
      this.endSessionTimeLeft <= this.WARNING_STAGE_1_IN_MILLISECONDS
    ) {
      this.setTimeOutState(TimeOut.stage1Warning);
    } else {
      this.setTimeOutState(TimeOut.notShown);
    }

    this.currentTime = DateTime.now().toMillis();
  };
}
