import { FunctionComponent } from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { LicenceMessage } from "@modules/settings/screens/users/LicenceMessage.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { Notifications } from "./Notifications.tsx";

export const Messages: FunctionComponent = () => {
  return (
    <>
      <When permission={Permission.LicencingAllowed}>
        <LicenceMessage />
      </When>

      <Notifications />
    </>
  );
};
