import { DateTime, last } from "@bps/utils";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { PatientEventsData } from "./PatientAppointmentsSearch.types.ts";

export const getPatientEventsData = (
  calendarEvents: CalendarEvent[],
  patients: Contact[]
): PatientEventsData[] => {
  const patientsEventsMap: Map<string, CalendarEvent[]> = new Map(
    patients.map(patient => [patient.id, []])
  );

  calendarEvents.forEach(calendarEvent => {
    calendarEvent.activeAttendees.forEach(attendee => {
      patientsEventsMap.get(attendee.attendeeId)?.push(calendarEvent);
    });
  });

  return patients.map((patient: Contact) => {
    const patientCalendarEvents = patientsEventsMap.get(patient.id) ?? [];

    const hasAppointments = !!patientCalendarEvents?.length;

    const upcomingAppointment = hasAppointments
      ? patientCalendarEvents.filter(
          (event: CalendarEvent) =>
            event.startDateTime > DateTime.now().startOf("hour") &&
            event.endDateTime > DateTime.now()
        )
      : undefined;

    const lastAppointment = hasAppointments
      ? last(
          patientCalendarEvents.filter(
            (event: CalendarEvent) =>
              event.startDateTime < DateTime.now().startOf("hour") &&
              event.endDateTime < DateTime.now()
          )
        )
      : undefined;

    return {
      patient,
      upcomingAppointment: upcomingAppointment?.length
        ? upcomingAppointment[0]
        : undefined,
      lastAppointment
    };
  });
};
