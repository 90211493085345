import { routes } from "@libs/routing/routes.ts";

import { NavBarItem } from "./NavBarItem.tsx";

export const ReportNavButton = () => {
  return (
    <NavBarItem
      text="Reports"
      permissions={routes.reports.basePath.permissions}
      permissionsOperator={routes.reports.basePath.permissionsOperator}
      to={routes.reports.basePath.pattern}
    />
  );
};
