import { observer } from "mobx-react-lite";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { Nav } from "@ui-components/navigation/Nav.tsx";

export const addContactNavLink = (
  label: string,
  practice: PracticeStore,
  options: {
    contactType: ContactType;
    showBorder?: boolean;
  }
) => ({
  name: label,
  permissions: [
    Permission.ContactRead,
    Permission.ContactWrite,
    Permission.PatientRead,
    Permission.PatientWrite
  ],
  onClick: () => practice.ui.showAddContact(options.contactType),
  showBorder: !!options.showBorder
});

export const getAddressBookNavLinks = (
  { practice }: IRootStore,
  patientLabel: string
) => [
  addContactNavLink(`New ${patientLabel.toLocaleLowerCase()}`, practice, {
    contactType: ContactType.Patient,
    showBorder: true
  }),
  {
    name: `${patientLabel}s`,
    path: routes.addressBook.patient
  },
  {
    name: `Non-${patientLabel.toLocaleLowerCase()}s`,

    path: routes.addressBook.individuals
  },
  {
    name: "Organisations",
    path: routes.addressBook.organisations
  }
];

export const AddressBookNavButton = observer(() => {
  const store = useStores();
  const patientLabel = usePatientLabel(true);

  return (
    <Nav
      renderAsButton
      buttonProps={{ text: "Address book" }}
      links={getAddressBookNavLinks(store, patientLabel)}
    />
  );
});
