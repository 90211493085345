import { observer } from "mobx-react-lite";

import { useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { Nav } from "@ui-components/navigation/Nav.tsx";

import { addContactNavLink } from "./AddressBookNavButton.tsx";
import { apptNavLink } from "./AppointmentsNavButton.tsx";
import { newInvoiceNavLink, newPaymentNavLink } from "./FinanceNavButton.tsx";

export const MoreNavButton = observer(() => {
  const { booking, practice, core, billing } = useStores();

  const theme = useTheme();

  const patientLabel = usePatientLabel(false);

  if (
    !core.hasPermissions([
      Permission.InvoiceCreate,
      Permission.PaymentCreate,
      Permission.PreReleaseAccInvoices,
      Permission.AccountHistoryAllowed
    ]) &&
    !core.hasPermissions([
      Permission.ContactRead,
      Permission.ContactWrite,
      Permission.PatientRead,
      Permission.PatientWrite
    ]) &&
    !core.hasPermissions(
      [Permission.InvoiceCreate, Permission.PaymentCreate],
      "or"
    )
  ) {
    return null;
  }

  return (
    <Nav
      renderAsButton
      buttonProps={{
        hideChevron: true,
        iconProps: { iconName: "add" },
        gapSpace: 12,
        buttonStyles: {
          root: {
            height: 24,
            width: 24,
            minWidth: 24,
            alignSelf: "center",
            border: `1px solid ${theme.palette.white}`,
            borderRadius: 4
          },
          icon: { fontSize: 12 },
          menuIcon: { display: "none" }
        }
      }}
      links={[
        apptNavLink(booking),
        addContactNavLink(`New ${patientLabel}`, practice, {
          contactType: ContactType.Patient
        }),
        addContactNavLink(`New non-${patientLabel}`, practice, {
          contactType: ContactType.Patient
        }),
        addContactNavLink("New organisation", practice, {
          contactType: ContactType.Organisation,
          showBorder: true
        }),
        newInvoiceNavLink(billing),
        newPaymentNavLink
      ]}
    />
  );
});
