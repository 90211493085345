import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Header, Stack, useScreenSize, useTheme } from "@bps/fluent-ui";
import { BusinessRoleClasses } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AddressBookNavButton } from "./AddressBookNavButton.tsx";
import { AdminNavButton } from "./AdminNavButton.tsx";
import { AppointmentNavButton } from "./AppointmentsNavButton.tsx";
import { FinanceNavButton } from "./FinanceNavButton.tsx";
import { MenuNavButton } from "./MenuNavButton.tsx";
import { NavBarItem } from "./NavBarItem.tsx";
import { PatientAppointmentsSearch } from "./PatientAppointmentSearch/PatientAppointmentsSearch.tsx";
import { ReportNavButton } from "./ReportNavButton.tsx";
import { ToDoNavButton } from "./ToDoNavButton.tsx";
import { UserBox } from "./UserBox.tsx";

export enum HostName {
  LocalHost = "localhost",
  Deva = "deva",
  Uata = "uata",
  Proda = "proda"
}

const NavBarBase: FunctionComponent = observer(() => {
  const { width: screenWidth } = useScreenSize();
  const { routing, practice } = useStores();
  const theme = useTheme();
  const { isPatientAppointmentsSearchOnFocus } = practice.ui;

  const NAV_BREAK_POINT = 1480;

  const collapseMode = screenWidth <= NAV_BREAK_POINT;

  const getIconsText = (text: string) => {
    if (isPatientAppointmentsSearchOnFocus) {
      if (!collapseMode) {
        return undefined;
      }
    }
    return text;
  };

  const getThemeColor = (hostname: string) => {
    if (hostname.startsWith(HostName.LocalHost)) {
      return theme.palette.teal;
    } else if (hostname.startsWith(HostName.Uata)) {
      return theme.semanticColors.mainHeaderBackground;
    } else if (hostname.startsWith(HostName.Deva)) {
      return theme.palette.themeDark;
    } else {
      return theme.semanticColors.mainHeaderBackground;
    }
  };

  const headerColor = getThemeColor(window.location.hostname);
  return (
    <Header
      logoProps={{
        onClick: () => routing.push(routes.calendarEvents.basePath.pattern)
      }}
      styles={{
        root: {
          borderBottom: `2px solid ${theme.appointmentStatusesColors.completedSecondary}`,
          backgroundColor: headerColor,
          ".ms-Button": {
            backgroundColor: headerColor
          }
        }
      }}
      userBox={<UserBox />}
    >
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 24 }}
        styles={{ root: { flexGrow: 1 } }}
      >
        {collapseMode ? (
          <MenuNavButton />
        ) : (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
            <AppointmentNavButton />
            <AddressBookNavButton />
            <ToDoNavButton />
            <AdminNavButton />
            <FinanceNavButton />
            <ReportNavButton />
          </Stack>
        )}
      </Stack>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{
          childrenGap: 24
        }}
        styles={{ root: { flexGrow: 1 } }}
      >
        <NavBarItem
          iconName="Calendar"
          text={getIconsText("Appt book")}
          permissionsOperator={
            routes.calendarEvents.basePath.permissionsOperator
          }
          permissions={routes.calendarEvents.basePath.permissions}
          to={routes.calendarEvents.basePath.pattern}
        />

        <NavBarItem
          businessRoleClasses={BusinessRoleClasses.Provider}
          iconName="Mail"
          text={getIconsText("Inbox")}
          to={routes.userInbox.basePath.pattern}
        />
        <PatientAppointmentsSearch collapseMode={collapseMode} />
      </Stack>
    </Header>
  );
});

export const NavBar = withFetch(
  x => [x.booking.ref.appointmentStatuses.load()],
  NavBarBase
);
