export interface InactivityPinFormValues {
  a: string | undefined;
  b: string | undefined;
  c: string | undefined;
  d: string | undefined;
  e: string | undefined;
  f: string | undefined;
  invalidPin: boolean | undefined;
  showPin: boolean;
}

export const PIN_LENGTH = 6;

export const names: (keyof InactivityPinFormValues)[] = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f"
];
