import { observer } from "mobx-react-lite";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useDraftRoute } from "@modules/billing/screens/draft-items/hooks/useDraftRoute.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { Nav } from "@ui-components/navigation/Nav.tsx";

import { getAddressBookNavLinks } from "./AddressBookNavButton.tsx";
import { getAdminNavLinks } from "./AdminNavButton.tsx";
import { getAppointmentNavLinks } from "./AppointmentsNavButton.tsx";
import { getFinanceNavLinks } from "./FinanceNavButton.tsx";
import { getToDoNavLinks } from "./ToDoNavButton.tsx";

export const MenuNavButton = observer(() => {
  const store = useStores();

  const patientLabel = usePatientLabel(true);
  const goToDrafts = useDraftRoute();
  const appointmentNavLinks = getAppointmentNavLinks(store);
  const addressBookNavLinks = getAddressBookNavLinks(store, patientLabel);

  const adminNavLinks = getAdminNavLinks(store, patientLabel);
  const financeNavLinks = getFinanceNavLinks(store, goToDrafts);
  return (
    <Nav
      renderAsButton
      buttonProps={{ text: "Menu", hideChevron: true }}
      links={[
        {
          name: "Appointments",
          links: appointmentNavLinks
        },
        {
          name: "Address book",
          links: addressBookNavLinks
        },
        {
          name: "To do",
          links: getToDoNavLinks()
        },
        {
          name: "Admin",
          links: adminNavLinks
        },
        {
          name: "Finance",
          links: financeNavLinks,
          permissions: [Permission.AccountHistoryAllowed]
        },
        {
          name: "Reports",
          path: routes.reports.basePath
        }
      ]}
    />
  );
});
