import { Fragment, FunctionComponent, useRef } from "react";
import { useFormState } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  FieldItemError,
  FontSizes,
  IMaskedTextField,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { next } from "@bps/utils";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  InactivityPinFormValues,
  names,
  PIN_LENGTH
} from "./InactivityPinForm.types.ts";

const BACKSPACE_KEY = "Backspace";
const ARROW_LEFT_KEY = "ArrowLeft";
const ARROW_RIGHT_KEY = "ArrowRight";

export const InactivityPinFormFields: FunctionComponent = () => {
  const { values } = useFormState<InactivityPinFormValues>();
  const {
    values: { invalidPin, showPin, ...pinValues }
  } = useFormState<InactivityPinFormValues>();

  const theme = useTheme();
  const refs = useRef<Record<string, IMaskedTextField>>({});
  const sortedPinValues = Object.keys(pinValues)
    .sort()
    .map(key => pinValues[key]);

  const pin = sortedPinValues.join("");
  const filledIn = pin.length === PIN_LENGTH;

  const color =
    !filledIn || !values.invalidPin
      ? theme.palette.themePrimary
      : theme.semanticColors.errorText;

  const invalid = filledIn && values.invalidPin;

  return (
    <Stack>
      <Fieldset
        horizontal
        tokens={{ childrenGap: 8 }}
        verticalAlign="center"
        horizontalAlign="space-between"
      >
        {names.map(n => (
          <Fragment key={n}>
            <TextInputField
              {...dataAttribute(DataAttributes.Element, `pin-field-${n}`)}
              componentRef={r => {
                if (r && !refs.current[n]) {
                  refs.current[n] = r;
                }
              }}
              invalid={invalid}
              name={n}
              trimmed
              onFocus={evt => {
                evt.target.setSelectionRange(0, 1);
              }}
              onKeyUp={evt => {
                const shouldNotMoveCursor =
                  evt.key.length > 1 &&
                  evt.code !== ARROW_LEFT_KEY &&
                  evt.code !== ARROW_RIGHT_KEY &&
                  evt.code !== BACKSPACE_KEY;

                if (shouldNotMoveCursor) return;

                // Focus the next or previous input after a value has been
                // entered or deleted respectively{
                const nextField = next<IMaskedTextField>(
                  refs.current,
                  n,
                  evt.key === ARROW_LEFT_KEY || evt.code === BACKSPACE_KEY
                );
                if (nextField) {
                  nextField.focus();
                }
              }}
              maxLength={1}
              styles={{
                field: {
                  fontSize: FontSizes.xLargePlus,
                  lineHeight: 20,
                  textAlign: "center",
                  height: 54,
                  width: 48,
                  color,
                  position: "relative"
                },
                fieldGroup: {
                  height: "auto",
                  borderColor: color,
                  "&:hover": { borderColor: color }
                }
              }}
              parse={v => {
                // if value is empty string return undefined
                if (!v) {
                  return undefined;
                }
                return v;
              }}
              format={v => {
                if (showPin) {
                  return v ?? ""; // it must be "" to keep input to be controlled element
                } else {
                  //  render bullets instead of values if showPin is false
                  return v ? "⦁" : "";
                }
              }}
            />
          </Fragment>
        ))}
      </Fieldset>

      {/* Validation message in a case PIN is not correct */}
      {invalidPin && (
        <FieldItemError name="incorrect-pin" errorMessage="Incorrect PIN" />
      )}

      <CheckboxField
        inputProps={{ ...dataAttribute(DataAttributes.Element, "show-pin") }}
        name={nameof<InactivityPinFormValues>("showPin")}
        label="Show PIN"
        styles={{ root: { marginTop: 8 } }}
      />
    </Stack>
  );
};
