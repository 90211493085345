import { UnsavedEndUserSessionDialog } from "app-shell/session/components/UserSessionCoachMarkModal/UnsavedEndUserSessionDialog.tsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import {
  Coachmark,
  DirectionalHint,
  IButtonProps,
  Stack,
  TeachingBubbleContent,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { useAuthorisationContext } from "../../context/AuthorisationContext.tsx";
import { TimeOut } from "../../context/UserSessionTimeTracker.ts";

interface UserSessionCoachMarkModalInterface {
  targetButton: HTMLDivElement | null;
}

export const UserSessionCoachMarkModal: React.FC<UserSessionCoachMarkModalInterface> =
  observer(({ targetButton }) => {
    const [hiddenUnsavedDialog, setHiddenUnsavedDialog] =
      useState<boolean>(false);

    const handleUnsavedEndUserSessionDialogCancel = () => {
      setHiddenUnsavedDialog(true);
    };

    const { palette, semanticColors } = useTheme();
    const { userSessionTimeTracker, logoutAllClients } =
      useAuthorisationContext();

    const timeOutState = userSessionTimeTracker.timeOutState;

    useEffect(() => {
      if (
        userSessionTimeTracker.endSessionTimeLeftInMinutes &&
        userSessionTimeTracker.endSessionTimeLeftInMinutes <=
          userSessionTimeTracker.endSessionWarningInMinutes
      ) {
        logoutAllClients();
      }
    }, [
      logoutAllClients,
      userSessionTimeTracker.endSessionTimeLeftInMinutes,
      userSessionTimeTracker.endSessionWarningInMinutes
    ]);

    const isCoachmarkVisible =
      timeOutState !== TimeOut.notShown &&
      !userSessionTimeTracker.hasClosedCoachMark;

    //button props
    const signOutButtonProps: IButtonProps = {
      text: "Sign out & discard changes",
      onClick: async () => {
        await logoutAllClients();
      }
    };

    const buttonEffect = {
      color: palette.black,
      border: "1.5px solid black",
      borderColor: palette.black,
      backgroundColor: semanticColors.buttonBackgroundHovered
    };

    const buttonEffectsStyles = {
      "&:hover": buttonEffect,
      "&:focus": buttonEffect,
      "&:active": buttonEffect
    };

    const closeButtonProps: IButtonProps = {
      text: "Close",
      onClick: () => {
        runInAction(() => {
          userSessionTimeTracker.hasClosedCoachMark = true;
        });
      }
    };

    return (
      <div>
        {isCoachmarkVisible && (
          <Coachmark
            target={targetButton}
            positioningContainerProps={{
              directionalHint: DirectionalHint.bottomAutoEdge,
              doNotLayer: false
            }}
            beaconColorOne={semanticColors.errorText}
            beaconColorTwo={semanticColors.errorText}
            color={semanticColors.errorText}
            styles={{}}
          >
            <TeachingBubbleContent
              headline={`${userSessionTimeTracker.endSessionTimeLeftInMinutes} minute sign out timer`}
              closeButtonAriaLabel="Close"
              primaryButtonProps={signOutButtonProps}
              secondaryButtonProps={closeButtonProps}
              styles={{
                bodyContent: {
                  backgroundColor: palette.white,
                  border: `2px solid ${semanticColors.errorText}`
                },
                subText: { color: semanticColors.errorText },
                headline: { color: semanticColors.errorText },
                primaryButton: {
                  color: palette.black,
                  border: "1.5px solid",
                  borderColor: palette.black,
                  textDecoration: "none",
                  minWidth: 0,
                  buttonEffectsStyles
                },
                secondaryButton: {
                  textDecorationColor: palette.black,
                  backgroundColor: palette.white,
                  border: "1.5px solid",
                  borderColor: palette.black,
                  ".ms-Button-label": { color: palette.black },
                  buttonEffectsStyles
                }
              }}
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <Text
                  styles={{
                    root: {
                      color: palette.redDark
                    }
                  }}
                >
                  {`In ${userSessionTimeTracker.endSessionTimeLeftInMinutes} minutes, you will be signed out to reauthenticate.`}
                </Text>
                <Text
                  styles={{
                    root: {
                      fontWeight:
                        userSessionTimeTracker.timeOutState !==
                        TimeOut.stage1Warning
                          ? "900"
                          : "400",
                      color: palette.redDark
                    }
                  }}
                >
                  If you have unsaved changes, these will be lost
                </Text>
              </Stack>
            </TeachingBubbleContent>
          </Coachmark>
        )}

        <UnsavedEndUserSessionDialog
          hidden={timeOutState !== TimeOut.logOutWarning || hiddenUnsavedDialog}
          onCancel={handleUnsavedEndUserSessionDialogCancel}
          onConfirm={logoutAllClients}
        />
      </div>
    );
  });
