import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { getCreateRouteInfo, routes } from "@libs/routing/routes.ts";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useStores } from "@stores/hooks/useStores.ts";

export const useTitle = () => {
  const { core } = useStores();
  const appInsightPlugin = useAppInsightsContext();
  const routesInfo = getCreateRouteInfo(routes);
  const location = useLocation();
  useEffect(() => {
    if (core.orgUnitsMap.size) {
      const matchedLocation = routesInfo.find(r => r.match(location.pathname));
      if (matchedLocation?.title) {
        const documentTitle = matchedLocation.title;

        if (core.location.parentOrgUnit?.name) {
          document.title = `${documentTitle} - ${core?.location.parentOrgUnit?.name}`;
        } else {
          document.title = documentTitle;
        }
        appInsightPlugin.trackPageView &&
          appInsightPlugin.trackPageView({ name: documentTitle });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesInfo, core.orgUnitsMap.size, location]);
};
