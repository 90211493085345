import { FunctionComponent, useRef } from "react";

import { useTheme } from "@bps/fluent-ui";
import {
  GAEvents,
  useGoogleAnalyticsEventTracking
} from "@libs/analytics/google/index.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { LinkButton } from "@ui-components/navigation/LinkButton.tsx";
import { getNavBarStylesSet } from "@ui-components/navigation/NavBar.styles.ts";

export const FeedbackNavButton: FunctionComponent = () => {
  const isButtonMounted = useRef<boolean>(false);
  const notifyGoogleAnalytics = useGoogleAnalyticsEventTracking(
    GAEvents.userVoiceTriggerInit
  );

  const theme = useTheme();
  const { core } = useStores();
  const { getCommandBarButtonStyle } = getNavBarStylesSet(theme);
  return (
    <LinkButton
      id="feedback_icon"
      componentRef={ref => {
        if (ref && !isButtonMounted.current) {
          isButtonMounted.current = true;
          // @TODO After BpId interrogation. The solution will be removed. jws and @types/jws libs must be removed too. Ilya S.
          import("jws").then(jws => {
            // generate JWS SSO UserVoice token
            const userVoiceToken = jws.sign({
              header: { alg: "HS256" },
              payload: {
                guid: core.user?.id,
                email: core.user?.email,
                // eslint-disable-next-line camelcase
                display_name: core.user?.fullName,
                // eslint-disable-next-line camelcase
                allow_forums: [window.appConfig.userVoiceForumId]
              },
              secret: "secret"
            });
            // pass user credentials with userVoiceToken to GTM
            notifyGoogleAnalytics({
              userEmail: core.user?.email,
              userName: core.user?.fullName,
              userVoiceToken
            });
          });
        }
      }}
      iconProps={{
        iconName: "Lightbulb"
      }}
      styles={getCommandBarButtonStyle()}
    />
  );
};
