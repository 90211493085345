import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";

import { FeedbackNavButton } from "./FeedbackNavButton.tsx";
import { HelpNavButton } from "./HelpNavButton.tsx";
import { MoreNavButton } from "./MoreNavButton.tsx";
import { NotificationNavButton } from "./NotificationNavButton.tsx";
import { UserNavButton } from "./UserNavButton.tsx";

export const UserBox: FunctionComponent = () => {
  return (
    <Stack
      horizontal
      tokens={{
        childrenGap: 16
      }}
    >
      <MoreNavButton />
      <HelpNavButton />
      <FeedbackNavButton />
      <NotificationNavButton />
      <UserNavButton />
    </Stack>
  );
};
