import { getAppInstance } from "@bps/http-client";

/**
 * The hook returns MSAL browserStorage entity and wipeInteractionInProgress method
 * that might be helpfully in a case you want to proceed MSAL action but previous interactions is in progress.
 * The thing is MSAL does not dispose an interaction if you for example closed a policy tab.
 */
export const useMsalBrowserStorage = () => {
  const instance = getAppInstance();

  const wipeInteractionInProgress = () => {
    const isInProgress =
      instance["browserStorage"].isInteractionInProgress(true);
    if (isInProgress) {
      instance["browserStorage"].setInteractionInProgress(false);
    }
  };

  return {
    browserStorage: instance["browserStorage"],
    wipeInteractionInProgress
  };
};
