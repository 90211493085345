import { MainLayout } from "app-shell/layouts/MainLayout.tsx";
import { Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";

import { NotFoundErrorBox, Overlay } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { BusinessRoleClasses } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { RouteDefinition } from "@stores/router/hooks/useRouteDefinitionsFilter.ts";

import { AuthorizationErrorBox } from "../../session/components/AuthorizationErrorBox.tsx";
import { AuthLayout } from "../AuthLayout.tsx";
import { flattenChildRoutes } from "../utils/use-app-router.utils.ts";

const practiceAndLocationRouteLayoutRoute: RouteDefinition = {
  importComponent: () =>
    import(
      "@modules/settings/screens/settings/components/PracticeBreadcrumbs.tsx"
    ),
  children: [
    {
      path: routes.settings.practices.basePath,
      importComponent: () =>
        import("@modules/settings/screens/practice/PracticeScreen.tsx")
    },
    {
      path: routes.settings.practices.openingHours,
      importComponent: () =>
        import(
          "@modules/settings/screens/practice/components/org-unit-opening-hours/OrgUnitOpeningHoursScreen.tsx"
        )
    },
    {
      path: routes.settings.practices.openingHoursOverrides,
      importComponent: () =>
        import(
          "@modules/settings/screens/practice/components/org-unit-opening-hours-overrides/OrgUnitOpeningHoursOverridesScreen.tsx"
        )
    },
    {
      path: routes.settings.practices.locations.new,
      importComponent: () =>
        import(
          /* webpackChunkName: "settings"*/ "@modules/settings/screens/practice/components/practice-locations/LocationDetailsScreen.tsx"
        )
    },
    {
      path: routes.settings.practices.locations.edit,
      importComponent: () =>
        import(
          /* webpackChunkName: "settings"*/ "@modules/settings/screens/practice/components/practice-locations/LocationDetailsScreen.tsx"
        )
    }
  ]
};

const usersRoutes: RouteDefinition = {
  importComponent: () =>
    import("@modules/settings/screens/users/components/UserBreadcrumbs.tsx"),
  children: [
    {
      path: routes.settings.users.basePath,
      importComponent: () =>
        import("@modules/settings/screens/users/UsersScreen.tsx")
    },
    {
      path: routes.settings.users.user,
      importComponent: () =>
        import("@modules/settings/screens/users/UserScreen.tsx")
    },
    {
      path: routes.settings.users.userClinicalView,
      importComponent: () =>
        import(
          "@modules/settings/screens/users/components/clinical/ClinicalScreen.tsx"
        )
    },
    {
      path: routes.settings.users.userWorkingHours,
      importComponent: () =>
        import(
          "@modules/settings/screens/users/components/user-working-hours/WorkingHoursScreen.tsx"
        )
    },
    {
      path: routes.settings.users.userWorkingHoursOverrides,
      importComponent: () =>
        import(
          "@modules/settings/screens/users/components/user-working-hours-overrides/UserWorkingHoursOverridesScreen.tsx"
        )
    },
    {
      path: routes.settings.users.reserves,
      importComponent: () =>
        import(
          "@modules/settings/screens/users/components/user-reserves/UserReservesScreen.tsx"
        )
    }
  ]
};

const commsRoutes: RouteDefinition[] = [
  {
    path: routes.settings.communications.schedule.new,
    importComponent: () =>
      import(
        "@modules/settings/screens/comms-schedules/CommsScheduleScreen.tsx"
      )
  },
  {
    path: routes.settings.communications.schedule.edit,
    importComponent: () =>
      import(
        "@modules/settings/screens/comms-schedules/CommsScheduleScreen.tsx"
      )
  },
  {
    path: routes.settings.communications.template.new,
    importComponent: () =>
      import(
        "@modules/settings/screens/comms-templates/CommsAddTemplateScreen.tsx"
      ),
    tenantLocation: Country.Australia
  },
  {
    path: routes.settings.communications.template.edit,
    importComponent: () =>
      import(
        "@modules/settings/screens/comms-templates/CommsAddTemplateScreen.tsx"
      ),
    tenantLocation: Country.Australia
  },

  {
    path: routes.settings.communications.confirmationCampaign.new,
    importComponent: () =>
      import(
        "@modules/settings/screens/comms-templates/CommsConfirmationCampaignScreen.tsx"
      )
  },
  {
    path: routes.settings.communications.confirmationCampaign.edit,
    importComponent: () =>
      import(
        "@modules/settings/screens/comms-templates/CommsConfirmationCampaignScreen.tsx"
      )
  }
];

const settingsSchedulesRoutes: RouteDefinition = {
  path: routes.settings.schedules.basePath,
  importComponent: () =>
    import("@modules/settings/screens/schedules/SchedulesScreen.tsx"),
  children: [
    {
      path: routes.settings.schedules.viewPath,
      importComponent: () =>
        import(
          "@modules/settings/screens/schedules/components/ScheduleView.tsx"
        )
    },
    {
      path: routes.settings.schedules.feePath,
      importComponent: () =>
        import(
          "@modules/settings/screens/schedules/components/fees/fee-view/FeeView.tsx"
        )
    },
    {
      index: true,
      importComponent: () =>
        import(
          "@modules/settings/screens/schedules/components/SchedulesList.tsx"
        )
    }
  ]
};

const settingsChildren: RouteDefinition[] = [
  usersRoutes,
  practiceAndLocationRouteLayoutRoute,
  ...commsRoutes,

  {
    path: routes.settings.appointmentTypes.basePath,
    importComponent: () =>
      import(
        "@modules/settings/screens/appointments-types/AppointmentTypesScreen.tsx"
      )
  },

  {
    path: routes.settings.bhb.onlineBooking,
    importComponent: () =>
      import(
        "@modules/settings/screens/bhb/online-settings/BhbOnlineSettingsScreen.tsx"
      )
  },

  {
    path: routes.settings.communications.schedules,
    importComponent: () =>
      import(
        "@modules/settings/screens/comms-schedules/CommsSchedulesScreen.tsx"
      )
  },

  {
    path: routes.settings.communications.templates,
    importComponent: () =>
      import(
        "@modules/settings/screens/comms-templates/CommsTemplatesScreen.tsx"
      ),
    tenantLocation: Country.Australia
  },
  {
    path: routes.settings.integrations.basePath,
    importComponent: () =>
      import("@modules/settings/screens/integrations/IntegrationsScreen.tsx")
  },
  settingsSchedulesRoutes,
  {
    path: routes.settings.accounts.basePath,
    importComponent: () =>
      import("@modules/settings/screens/settings/SettingsScreen.tsx")
  }
];

const settingsRoute: RouteDefinition = {
  path: routes.settings.basePath,
  importComponent: () =>
    import("@modules/settings/screens/settings/SettingsScreen.tsx"),
  redirectToClosestPermissiveRoute: true,
  children: settingsChildren
};

const todoRoutes: RouteDefinition = {
  importComponent: () => import("@modules/inbox/screens/todo/ToDoScreen.tsx"),
  children: [
    {
      path: routes.inbox.upload,
      importComponent: () =>
        import("@modules/inbox/screens/upload/UploadScreen.tsx")
    },
    {
      path: routes.inbox.incoming,
      importComponent: () =>
        import("@modules/inbox/screens/incoming/IncomingScreen.tsx")
    },
    {
      path: routes.tasks.inbox,
      importComponent: () =>
        import("@modules/inbox/screens/tasks/TasksInboxScreen.tsx")
    },
    {
      path: routes.tasks.clinical,
      importComponent: () =>
        import("@modules/inbox/screens/todo/components/ActivityOrTaskList.tsx")
    },
    {
      path: routes.tasks.reminders,
      importComponent: () =>
        import("@modules/inbox/screens/tasks/RemindersScreen.tsx")
    },
    {
      path: routes.userInbox.basePath,
      importComponent: () =>
        import("@modules/inbox/screens/user-inbox/UserInboxScreen.tsx"),
      businessRoleClasses: BusinessRoleClasses.Provider
    }
  ]
};

const clinicalRoutes = [
  {
    importComponent: () =>
      import(
        "@modules/clinical/screens/patient-record/PatientRecordScreen.tsx"
      ),
    path: routes.records.basePath,
    children: [
      {
        path: routes.records.record,
        importComponent: () =>
          import(
            "@modules/clinical/screens/patient-record/PatientRecordScreen.tsx"
          )
      },
      {
        path: routes.records.recordUpdate,
        importComponent: () =>
          import(
            "@modules/clinical/screens/patient-record/PatientRecordScreen.tsx"
          )
      },
      {
        path: routes.records.recordView,
        importComponent: () =>
          import(
            "@modules/clinical/screens/patient-record/PatientRecordScreen.tsx"
          )
      },
      {
        path: routes.records.encounter,
        importComponent: () =>
          import(
            "@modules/clinical/screens/patient-record/PatientRecordScreen.tsx"
          )
      },
      {
        path: routes.records.encounterView,
        importComponent: () =>
          import(
            "@modules/clinical/screens/patient-record/PatientRecordScreen.tsx"
          )
      },
      {
        path: routes.records.appointment,
        importComponent: () =>
          import(
            "@modules/clinical/screens/patient-record/PatientRecordScreen.tsx"
          )
      },
      {
        path: routes.records.appointmentView,
        importComponent: () =>
          import(
            "@modules/clinical/screens/patient-record/PatientRecordScreen.tsx"
          )
      }
    ]
  }
];

const billingRoutes: RouteDefinition[] = [
  {
    path: routes.accounts.basePath,
    importComponent: () =>
      import("@modules/billing/screens/billing-history/BillingScreen.tsx")
  },

  {
    path: routes.accounts.accInvoices.basePath,
    importComponent: () =>
      import("@modules/billing/screens/acc-invoices/AccInvoicesScreen.tsx"),
    tenantLocation: Country.NewZealand
  },
  {
    path: routes.accounts.accreditedBilling.basePath,
    importComponent: () =>
      import(
        "@modules/billing/screens/accredited-billing/AccreditedBillingScreen.tsx"
      ),
    tenantLocation: Country.NewZealand
  },
  {
    path: routes.accounts.account,
    importComponent: () =>
      import("@modules/billing/screens/account/AccountScreen.tsx")
  },
  {
    path: routes.accounts.invoices.new,
    importComponent: () =>
      import("@modules/billing/screens/invoice/InvoiceEditScreen.tsx")
  },
  {
    path: routes.accounts.invoices.adjust,
    importComponent: () =>
      import("@modules/billing/screens/invoice/InvoiceEditScreen.tsx")
  },
  {
    path: routes.accounts.invoices.invoice,
    importComponent: () =>
      import("@modules/billing/screens/invoice/InvoiceViewScreen.tsx")
  },
  {
    path: routes.accounts.draftItems.basePath,
    importComponent: () =>
      import("@modules/billing/screens/draft-items/DraftItemsScreen.tsx")
  },
  {
    path: routes.accounts.invoices.writeOff.new,
    importComponent: () =>
      import("@modules/billing/screens/invoice/write-off/WriteOffScreen.tsx")
  },
  {
    path: routes.accounts.invoices.writeOff.viewPath,
    importComponent: () =>
      import(
        "@modules/billing/screens/invoice/write-off/WriteOffViewScreen.tsx"
      )
  },
  {
    path: routes.accounts.statements.basePath,
    importComponent: () =>
      import("@modules/billing/screens/statement/StatementItemsScreen.tsx")
  },
  {
    path: routes.accounts.creditNotes.viewPath,
    importComponent: () =>
      import("@modules/billing/screens/credit-notes/CreditNoteViewScreen.tsx")
  },
  {
    path: routes.accounts.creditNotes.new,
    importComponent: () =>
      import("@modules/billing/screens/credit-notes/CreditNoteScreen.tsx")
  },
  {
    path: routes.accounts.payments.viewPath,
    importComponent: () =>
      import("@modules/billing/screens/payment/PaymentScreen.tsx")
  },
  {
    path: routes.accounts.allocations.new,
    importComponent: () =>
      import("@modules/billing/screens/allocation/AllocationScreen.tsx")
  },
  {
    path: routes.accounts.allocations.allocation,
    importComponent: () =>
      import("@modules/billing/screens/allocation/AllocationScreen.tsx")
  },
  {
    path: routes.accounts.refund,
    importComponent: () =>
      import("@modules/billing/screens/refund/RefundViewScreen.tsx")
  }
];

const accRoutes: RouteDefinition[] = [
  {
    path: routes.claims.basePath,
    importComponent: () =>
      import("@modules/acc/screens/claims/ClaimsScreen.tsx"),
    tenantLocation: Country.NewZealand
  },
  {
    path: routes.claims.edit,
    importComponent: () =>
      import("@modules/acc/screens/claim/ClaimEditScreen.tsx"),
    tenantLocation: Country.NewZealand
  },
  {
    path: routes.claims.management.edit,
    importComponent: () =>
      import(
        "@modules/acc/screens/claim-management/ClaimManagementEditScreen.tsx"
      ),
    tenantLocation: Country.NewZealand
  },
  {
    path: routes.claimAdjustment.edit,
    importComponent: () =>
      import(
        "@modules/acc/screens/claim-adjustment/ClaimAdjustmentEditScreen.tsx"
      ),
    tenantLocation: Country.NewZealand
  }
];

const lazyRoutes: RouteDefinition[] = [
  settingsRoute,
  todoRoutes,
  ...clinicalRoutes,
  ...billingRoutes,
  ...accRoutes,
  {
    path: routes.documentWriter.document,
    importComponent: () =>
      import(
        "@modules/clinical/screens/document-writer/DocumentWriterScreen.tsx"
      )
  },
  {
    path: routes.documentWriter.template,
    importComponent: () =>
      import(
        "@modules/clinical/screens/template-writer/TemplateWriterScreen.tsx"
      )
  },

  {
    path: routes.addressBook.basePath,
    importComponent: () =>
      import("@modules/practice/screens/address-book/PeopleScreen.tsx"),
    children: [
      {
        index: true,
        importComponent: () =>
          import("@modules/practice/screens/address-book/PatientScreen.tsx")
      },
      {
        path: routes.addressBook.organisations,
        importComponent: () =>
          import(
            "@modules/practice/screens/address-book/IndividualAndOrganisationScreen.tsx"
          )
      },
      {
        path: routes.addressBook.patient,
        importComponent: () =>
          import("@modules/practice/screens/address-book/PatientScreen.tsx")
      },
      {
        path: routes.addressBook.individuals,
        importComponent: () =>
          import(
            "@modules/practice/screens/address-book/IndividualAndOrganisationScreen.tsx"
          )
      }
    ]
  },

  {
    path: routes.recentPatients.basePath,
    importComponent: () =>
      import(
        "@modules/settings/screens/recent-patients/RecentPatientsScreen.tsx"
      )
  },

  {
    path: routes.contacts.contact,
    importComponent: () =>
      import("@modules/practice/screens/contact-details/ContactScreen.tsx")
  },
  {
    path: routes.calendarEvents.basePath,
    importComponent: () =>
      import(
        "@modules/booking/screens/booking-calendar/BookingCalendarScreen.tsx"
      ),
    redirect: routes.addressBook.patient.pattern
  },
  {
    path: routes.conditions.summary,
    importComponent: () =>
      import("@modules/clinical/screens/condition/ConditionScreen.tsx"),
    tenantLocation: Country.NewZealand
  },
  {
    path: routes.reports.basePath,
    importComponent: () => import("@modules/reports/screens/ReportsScreen.tsx")
  },
  {
    path: routes.reports.view,
    importComponent: () =>
      import(
        /* webpackChunkName: "report"*/ "@modules/reports/screens/ReportsScreen.tsx"
      )
  }
];

export const useAppRouter = () => {
  const createLazyRoute = (
    routeDefinition: RouteDefinition,
    siblingRoutes?: RouteDefinition[]
  ): RouteObject => {
    const childrenRoutes = routeDefinition.children?.map(r =>
      createLazyRoute(
        r,
        routeDefinition.redirectToClosestPermissiveRoute
          ? flattenChildRoutes(routeDefinition)
          : siblingRoutes
      )
    );

    const authLayoutRoute: RouteObject = {
      element: (
        <Suspense fallback={<Overlay styles={{ root: { zIndex: 100 } }} />}>
          <AuthLayout
            permissions={routeDefinition.path?.permissions}
            permissionsOperator={routeDefinition.path?.permissionsOperator}
            unauthorised={AuthorizationErrorBox}
            tenantLocation={routeDefinition.tenantLocation}
            redirect={routeDefinition.redirect}
            businessRoleClasses={routeDefinition.businessRoleClasses}
            businessRoleClassesOperator={
              routeDefinition.businessRoleClassesOperator
            }
            fallbackRoutes={siblingRoutes}
          />
        </Suspense>
      )
    };

    let route: RouteObject = {
      path: routeDefinition.path?.pattern,
      lazy: async () => {
        const component = await routeDefinition.importComponent();
        return { Component: component.default };
      },
      children: childrenRoutes
    };

    if (routeDefinition.index) {
      route = {
        ...route,
        index: routeDefinition.index,
        children: undefined
      };
    }

    return {
      ...authLayoutRoute,
      children: [route]
    };
  };

  const getAppRoutes = (
    lazyRouteDefinition: RouteDefinition[]
  ): RouteObject[] => {
    const notFoundRoute: RouteObject = {
      path: "*",
      element: <NotFoundErrorBox showLogo />
    };

    const dashboardRoute: RouteObject = {
      index: true,
      element: <Navigate to={routes.calendarEvents.basePath.path({})} />
    };

    const lazyRoutes = lazyRouteDefinition.map(r => createLazyRoute(r));
    return [
      {
        path: "/",
        element: <MainLayout />,
        children: [notFoundRoute, dashboardRoute, ...lazyRoutes]
      }
    ];
  };

  return getAppRoutes(lazyRoutes);
};
