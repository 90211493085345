import { RouteDefinition } from "@stores/router/hooks/useRouteDefinitionsFilter.ts";

export const flattenChildRoutes = (routeDefinition: RouteDefinition) => {
  const flattenChildren = (children: RouteDefinition[]): RouteDefinition[] => {
    return children
      .flatMap(routeDef =>
        routeDef.children
          ? [routeDef, ...flattenChildren(routeDef.children)]
          : [routeDef]
      )
      .filter(r => !!r.path);
  };

  return flattenChildren(routeDefinition.children ?? []);
};
