import { AboutDialog } from "app-shell/layouts/AboutDialog.tsx";
import { useState } from "react";

import { appContext } from "@libs/config/app-context.utils.ts";
import { Nav } from "@ui-components/navigation/Nav.tsx";

export const KB_URL_BASE = "https://kb.bpsoftware.net";

export const HelpNavButton = () => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <div>
      <AboutDialog hidden={isHidden} onClose={() => setIsHidden(true)} />
      <Nav
        renderAsButton
        buttonProps={{ text: "Help" }}
        links={[
          {
            key: "knowledgeBase",
            name: "Knowledge base",
            url: `${KB_URL_BASE}/bpcloud/home.htm`,
            target: "_blank"
          },
          {
            key: "releaseNotes",
            name: "Release notes",
            url: `${KB_URL_BASE}/bpomni/WhatsNew/WhatsNew.htm`,
            target: "_blank"
          },
          {
            key: "training",
            name: "Training",
            url: "https://bpsoftware.net/bp-learning-2/",
            target: "_blank"
          },
          {
            key: "version",
            name: "Version",
            onClick: () => setIsHidden(false),
            showBorder: true
          },
          {
            key: "feedback",
            name: "Give us feedback",
            url: `https://bpsoftware.uservoice.com/forums/${appContext.config.userVoiceForumId}`,
            target: "_blank"
          }
        ]}
      />
    </div>
  );
};
