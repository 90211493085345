import { parse } from "query-string";

import { appContext } from "@libs/config/app-context.utils.ts";
import { routes } from "@libs/routing/routes.ts";

type StringDict = {
  [key: string]: string;
};

enum AuthPolicies {
  userSignup = "userSignup",
  tenantSignup = "signup",
  resetpwd = "resetpwd",
  resetmfa = "resetmfa",
  resetPin = "resetPin"
}

enum AuthPoliciesQueries {
  inviteToken = "inviteToken",
  email = "email",
  tid = "tid",
  aid = "aid"
}

export const useMsalConfig = () => {
  const redirectUri = `${window.location.origin}${routes.calendarEvents.basePath.pattern}`;

  const {
    config: {
      authentication: { cacheLocation },
      b2cAuthentication: {
        clientId,
        scopes,
        tenantName,
        signinPolicy,
        userSignupPolicy,
        resetPasswordPolicy,
        resetMfaPolicy,
        resetPinPolicy,
        tenantSignupPolicy
      }
    }
  } = appContext;

  let extraQueryParameters: StringDict | undefined;
  let authority = signinPolicy;
  const policy = window.location
    ? Object.values(AuthPolicies).find(
        t => t === window.location.pathname.split("/")[1]
      )
    : undefined;

  const parsedFilter = parse(window.location.search);

  const tid = parsedFilter[AuthPoliciesQueries.tid]?.toString();

  switch (policy) {
    case AuthPolicies.userSignup:
      authority = userSignupPolicy;
      const inviteToken =
        parsedFilter[AuthPoliciesQueries.inviteToken]?.toString();
      inviteToken && (extraQueryParameters = { inviteToken });
      break;
    case AuthPolicies.resetpwd:
      authority = resetPasswordPolicy;
      const email = parsedFilter[AuthPoliciesQueries.email]?.toString();
      email && tid && (extraQueryParameters = { email, tid });
      break;
    case AuthPolicies.resetmfa:
      authority = resetMfaPolicy;
      const mfaEmail = parsedFilter[AuthPoliciesQueries.email]?.toString();
      const mfaId = parsedFilter[AuthPoliciesQueries.tid]?.toString();
      mfaEmail && mfaId && (extraQueryParameters = { mfaEmail, mfaId });
      break;
    case AuthPolicies.resetPin:
      authority = resetPinPolicy;
      const pinEmail = parsedFilter[AuthPoliciesQueries.email]?.toString();
      const pinId = parsedFilter[AuthPoliciesQueries.tid]?.toString();
      pinEmail && pinId && (extraQueryParameters = { pinEmail, pinId });
      break;
    case AuthPolicies.tenantSignup:
      authority = tenantSignupPolicy;
      const aid = parsedFilter[AuthPoliciesQueries.aid]?.toString();

      if (aid && tid) {
        extraQueryParameters = { aid, tid };
      } else if (aid) {
        extraQueryParameters = { aid };
      }

      break;
  }

  return {
    authority,
    clientId,
    scopes,
    postAuthenticateRedirectUrl: redirectUri,
    redirectUri,
    knownAuthorities: [`${tenantName}.b2clogin.com`],
    cacheLocation,
    extraQueryParameters
  };
};
