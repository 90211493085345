/**
 * UserActivityTracker monitors a user activity (like mouse and keyboard actions)
 * timeExceedCallback is called when timeout is exceeded.
 * also the class is responsible for persisting lastActionTime in milliseconds
 */
import debounce from "lodash.debounce";
import { computed } from "mobx";

import { ILogger } from "@bps/http-client";
import { StorageProperties } from "@libs/constants/storage-properties.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

const NODE_TEXT_ENV = "test";
export class UserActivityTracker {
  constructor(
    private hardLockCallback: () => void,
    private softLockCallback: () => void,
    private userExperience: UserExperienceStore
  ) {
    this.logger = userExperience.root.notification.logger;
    if (import.meta.env.NODE_ENV !== NODE_TEXT_ENV) {
      this.logger.info("UserActivityTracker - initialized");
    }
  }
  logger: ILogger;

  public SOFT_TIMEOUT_IN_MILLISECONDS = 900000; // 15 min
  public USER_ACTIVITY_TIMEOUT = 3000; // 3 sec
  public VISIBILITY_STATE: DocumentVisibilityState = "visible";

  private softLockInterval: NodeJS.Timeout;
  private readonly events = [
    "mousedown",
    "mousemove",
    "keypress",
    "scroll",
    "touchstart",
    "readystatechange"
  ];
  private readonly visibilityChangeEvent = "visibilitychange";

  private get testingSoftTimeoutInMilliseconds(): number | undefined {
    const value = localStorage.getItem(StorageProperties.lockedTestingTimeout);
    if (!value) return undefined;
    return Number(value);
  }

  @computed
  private get softTimeoutDisabled(): boolean {
    const value = localStorage.getItem(StorageProperties.lockedTestingDisabled);
    if (value === null) {
      return !(
        this.userExperience.tenantSecuritySettings?.inactivityTimeoutEnabled ??
        true
      );
    }

    return Boolean(value);
  }

  @computed
  private get timeOutMilliseconds(): number {
    let timeOutMilliseconds: number;

    const tenantTimeoutThreshold =
      this.userExperience.tenantSecuritySettings
        ?.inactivityTimeoutThresholdMinutes;

    if (!!tenantTimeoutThreshold) {
      timeOutMilliseconds = tenantTimeoutThreshold * 60000;
    } else {
      timeOutMilliseconds =
        this.testingSoftTimeoutInMilliseconds ??
        this.SOFT_TIMEOUT_IN_MILLISECONDS;
    }

    return timeOutMilliseconds;
  }

  private calloutsHostElement = document.querySelector(
    "#fluent-default-layer-host"
  );

  private userActivityCallback = debounce(() => {
    if (!this.softTimeoutDisabled) {
      clearTimeout(this.softLockInterval);

      this.softLockInterval = setTimeout(
        this.softLockCallback,
        this.timeOutMilliseconds
      );
    }
  }, this.USER_ACTIVITY_TIMEOUT);

  private appVisibilityCallback = () => {
    if (document.visibilityState === this.VISIBILITY_STATE) {
      this.hardLockCallback();
    }
  };

  public subscribe = () => {
    // 1. Subscribe tab/app visibility change
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
    document.addEventListener(
      this.visibilityChangeEvent,
      this.appVisibilityCallback
    );

    // 2. Subscribe user screen actions
    this.events.forEach(event => {
      document.addEventListener(event, this.userActivityCallback);
      // Apart from document we subscribe Fluent UI callouts host element. The reason is all Fluent UI popups bubbled events are disabled by default. Ilya S.
      this.calloutsHostElement &&
        this.calloutsHostElement.addEventListener(
          event,
          this.userActivityCallback,
          true
        );
    });
    if (import.meta.env.NODE_ENV !== NODE_TEXT_ENV) {
      this.logger.info(
        "UserActivityTracker - subscribed visibilitychange and user actions"
      );
    }
  };

  public unsubscribe = () => {
    clearTimeout(this.softLockInterval);
    document.removeEventListener(
      this.visibilityChangeEvent,
      this.appVisibilityCallback
    );

    this.events.forEach(event => {
      document.removeEventListener(event, this.userActivityCallback);
      this.calloutsHostElement &&
        this.calloutsHostElement.addEventListener(
          event,
          this.userActivityCallback,
          true
        );
    });
  };
}
