import { FunctionComponent, PropsWithChildren } from "react";
import { FallbackProps } from "react-error-boundary";

import { ErrorAlert } from "@ui-components/Alert.tsx";

export const ErrorBoundaryFallback: FunctionComponent<
  PropsWithChildren<FallbackProps>
> = ({ error, resetErrorBoundary, children }) => {
  if (error) {
    return (
      <ErrorAlert
        showReloadLink={true}
        //return  error to get stack trace
        error={error}
        onReload={resetErrorBoundary}
      />
    );
  }
  return <>{children}</>;
};
