import { runInAction } from "mobx";
import { FC } from "react";

import {
  IconButton,
  Link,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { AppointmentStartingPoints } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { getPatientSearchBoxStyles } from "./PatientAppointmentsSearch.styles.ts";

interface PatientAppointmentItemFooterProps {
  patient: Contact;
  nextAppointment: CalendarEvent | undefined;
  lastAppointment: CalendarEvent | undefined;
  onAddEventClick: (patientId: string) => void;
  onDismissCallout: () => void;
  setShowEditAppointmentModal: (value: string | undefined) => void;
}
export const PatientAppointmentItemFooter: FC<
  PatientAppointmentItemFooterProps
> = ({
  patient,
  onAddEventClick,
  nextAppointment,
  lastAppointment,
  onDismissCallout,
  setShowEditAppointmentModal
}) => {
  const { booking } = useStores();
  const theme = useTheme();
  const {
    appointmentFooterStyles,
    lastAndNextApptTextStyles,
    newApptIconStyles
  } = getPatientSearchBoxStyles(theme);

  const patientId = patient.id;

  const handleOnCreateNewAppointmentClick = () => {
    booking.ui.showCalendarEventDialog(
      {
        type: CalendarEventType.Appointment,
        initialValues: { patientId }
      },
      AppointmentStartingPoints.PatientSearchAddAppointmentButton
    );
    onAddEventClick(patientId);
  };

  const handleOnLastApptClick = () => {
    onDismissCallout();
    setShowEditAppointmentModal(patientId);
    onAddEventClick(patientId);
  };

  const handleOnNextApptClick = () => {
    booking.ui.showCalendarEventDialog({
      type: CalendarEventType.Appointment,
      id: nextAppointment?.id
    });
    runInAction(() => {
      booking.ui.isEditSingleEvent = true;
    });
    onAddEventClick(patientId);
  };

  return (
    <Stack
      horizontal
      verticalAlign="center"
      styles={appointmentFooterStyles}
      className="suggestionContainer__footer"
    >
      {nextAppointment ? (
        <Link
          styles={lastAndNextApptTextStyles}
          onClick={handleOnNextApptClick}
        >
          Next:{" "}
          {nextAppointment.startDateTime.toFormat(
            DATE_FORMATS.DAY_FORMAT_WITH_SHORT_DAY_OF_WEEK
          )}
        </Link>
      ) : (
        <Text styles={lastAndNextApptTextStyles}>No future appts</Text>
      )}
      {lastAppointment ? (
        <Link
          styles={lastAndNextApptTextStyles}
          onClick={handleOnLastApptClick}
        >
          All appointments
        </Link>
      ) : (
        <Text styles={lastAndNextApptTextStyles}>No past appts</Text>
      )}
      <TooltipHost content="New appointment">
        <IconButton
          styles={newApptIconStyles}
          iconProps={{ iconName: "AddEvent" }}
          onClick={handleOnCreateNewAppointmentClick}
        />
      </TooltipHost>
    </Stack>
  );
};
