import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo
} from "react";

import { DateTime } from "@bps/utils";
import {
  GAEvents,
  useGoogleAnalyticsEventTracking
} from "@libs/analytics/google/index.tsx";
import { ReactPlugin as AppInsightsReactPlugin } from "@microsoft/applicationinsights-react-js";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  AuthorisationContext,
  useAuthorisationContext
} from "./context/AuthorisationContext.tsx";
import { AuthorisationHelper } from "./context/AuthorisationHelper.ts";

interface SubscriptionsProviderProps
  extends PropsWithChildren<{
    appInsightPlugin: AppInsightsReactPlugin;
  }> {}

const SubscriptionsProviderBase: FunctionComponent<PropsWithChildren> =
  observer(({ children }) => {
    const { core } = useStores();
    const {
      userActivityTracker,
      subscribeBroadcastChannel,
      unsubscribeBroadcastChannel,
      userSessionTimeTracker
    } = useAuthorisationContext();

    const notifyGoogleAnalytics = useGoogleAnalyticsEventTracking(
      GAEvents.authenticated
    );

    const notifyGoogleAnalyticsDisposer = reaction(
      () => core.isLoggedIn,
      () => {
        if (core.user && core.location) {
          notifyGoogleAnalytics({
            userid: core.user.id,
            userEmail: core.user.email,
            userName: core.user.fullName,
            userCreated: DateTime.fromISO(
              core.user.changeLog?.createdDate
            )?.toDayDefaultFormat(),
            roles: core.user.businessRoles.join(","),
            location: core.location.address
          });
        }
      }
    );

    useEffect(() => {
      // subscribe user actions
      userActivityTracker.subscribe();
      // subscribe broadcast channel receiver
      subscribeBroadcastChannel();
      return () => {
        // unsubscribe user actions
        userActivityTracker.unsubscribe();
        // unsubscribe broadcast channel receiver
        unsubscribeBroadcastChannel();
        notifyGoogleAnalyticsDisposer();
        //clear coachMark's interval timer
        userSessionTimeTracker.disposes();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>;
  });

export const SubscriptionsProvider: FunctionComponent<SubscriptionsProviderProps> =
  observer(({ appInsightPlugin, ...props }) => {
    const { core, userExperience } = useStores();

    const authorisationHelper = useMemo(
      () => new AuthorisationHelper(core, userExperience),
      [core, userExperience]
    );

    return (
      <AuthorisationContext.Provider value={authorisationHelper}>
        <SubscriptionsProviderBase {...props} />
      </AuthorisationContext.Provider>
    );
  });
