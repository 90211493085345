import React from "react";

import { IconButton, Stack, TooltipHost, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { When } from "@ui-components/withPerm.tsx";

import { getPatientSearchBoxStyles } from "./PatientAppointmentsSearch.styles.ts";

interface ActionButtonsProps {
  patient: Contact;
  onIconClick: (patientId: string) => void;
}
export const PatientSearchActionButtons: React.FC<ActionButtonsProps> = ({
  patient,
  onIconClick
}) => {
  const { practice, routing } = useStores();
  const theme = useTheme();
  const { actionButtonsStyles } = getPatientSearchBoxStyles(theme);

  const handleOnClinicalIconClick = (patientId: string) => {
    routing.push(
      routes.records.recordView.path({ id: patientId }),
      routing.getStateWithFromQuery()
    );
    onIconClick(patientId);
  };

  const handleOnEditIconClick = (patientId: string) => {
    practice.ui.showEditContact(PatientCardIds.patientHeader, patientId);
    onIconClick(patientId);
  };

  return (
    <Stack horizontal verticalAlign="center" styles={actionButtonsStyles}>
      <When permission={[Permission.EncounterRead, Permission.ClinicalRead]}>
        <TooltipHost content="View clinical record">
          <IconButton
            iconProps={{ iconName: "ContactCard" }}
            onClick={() => handleOnClinicalIconClick(patient.id)}
            styles={{ icon: { height: "17px" } }}
          />
        </TooltipHost>
      </When>
      <When permission={[Permission.PatientWrite]}>
        <TooltipHost content="Edit demographics">
          <IconButton
            iconProps={{ iconName: "Edit" }}
            onClick={() => handleOnEditIconClick(patient.id)}
            styles={{ icon: { fontSize: "15px" } }}
          />
        </TooltipHost>
      </When>
    </Stack>
  );
};
