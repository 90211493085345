import { ISearchBoxProps, ITheme } from "@bps/fluent-ui";
import { getNavBarStylesSet } from "@ui-components/navigation/NavBar.styles.ts";

export const getPatientSearchBoxStyles = (
  theme: ITheme,
  isSearchBoxOnFocus?: boolean,
  height?: number
) => {
  const { hoverColor, hoverPressedColor } = getNavBarStylesSet(theme);
  const searchBoxStyle: ISearchBoxProps["styles"] = {
    root: {
      backgroundColor: "none",
      borderStyle: "none",
      width: 250,
      transition: "width 400ms",
      visibility: isSearchBoxOnFocus ? "visible" : "hidden",
      "::after": {
        borderColor: theme.palette.white,
        borderWidth: 1,
        opacity: 0.6
      }
    },
    field: {
      cursor: "initial",
      color: theme.palette.white,
      "::placeholder": {
        color: theme.palette.white
      },
      ":focus::placeholder": {
        color: theme.palette.white,
        opacity: 0.6
      }
    },
    icon: { color: theme.palette.white },
    clearButton: {
      "& .ms-Button-icon": { color: theme.palette.white },
      "&:hover .ms-Button-icon": { color: theme.palette.white },
      "&:hover .ms-Button": {
        backgroundColor: hoverColor
      },
      "& button:active": {
        backgroundColor: hoverPressedColor
      }
    }
  };

  const personaWithIconsStyles = {
    root: {
      cursor: "initial",
      justifyContent: "space-between"
    }
  };

  const personaStyles = {
    details: {
      cursor: "pointer",
      paddingRight: 0,
      width: "100%"
    },
    root: {
      cursor: "initial",
      height: "auto",
      padding: "4px 12px"
    },
    primaryText: {
      fontSize: "14px",
      selectors: {
        "&:hover": {
          textDecoration: "underline"
        }
      }
    },
    secondaryText: {
      display: "inline",
      fontSize: "12px"
    }
  };

  const actionButtonsStyles = {
    root: {
      padding: "4px 8px"
    }
  };

  const appointmentFooterStyles = {
    root: {
      cursor: "initial",
      padding: "4px 8px",
      justifyContent: "space-between",
      height: "32px"
    }
  };

  const newApptIconStyles = {
    rootHovered: {
      backgroundColor: "none"
    }
  };

  const lastAndNextApptTextStyles = {
    root: {
      fontSize: "12px"
    }
  };

  const addNewPatientComponentStyles = {
    root: {
      height: 36,
      border: `1px solid ${theme.palette.neutralLight}`,
      borderTopWidth: 1,
      boxSizing: "border-box",
      width: "100%"
    }
  };

  const suggestionsContainerStyles = {
    suggestionsContainer: {
      maxHeight: height,
      overflow: "auto",
      "& .is-suggested .suggestionContainer__persona": {
        background: theme.palette.neutralLighter
      },
      "& button:not(.is-suggested) .suggestionContainer__footer": {
        backgroundColor: theme.palette.neutralLighterAlt
      },
      "& button .suggestionContainer__footer": {
        background: theme.palette.neutralQuaternaryAlt
      },
      "& button:hover .suggestionContainer__persona": {
        background: theme.palette.neutralLighter
      },
      "& button:hover .suggestionContainer__footer": {
        background: theme.palette.neutralLight
      },
      "& .ms-Suggestions-itemButton": { width: "100%" },
      "& .ms-Suggestions-item:hover": { background: "transparent" },
      "& .ms-Button-flexContainer": { width: "100%" }
    }
  };

  const recentSearchTitleContainerStyles = {
    root: {
      borderBottom: `1px solid ${theme.palette.neutralLight}`
    }
  };

  const recentSearchTextStyles = {
    root: {
      padding: "8px 0px 8px 12px",
      color: theme.palette.themePrimary
    }
  };

  return {
    searchBoxStyle,
    personaStyles,
    actionButtonsStyles,
    appointmentFooterStyles,
    addNewPatientComponentStyles,
    suggestionsContainerStyles,
    personaWithIconsStyles,
    recentSearchTitleContainerStyles,
    recentSearchTextStyles,
    newApptIconStyles,
    lastAndNextApptTextStyles
  };
};
