import { FunctionComponent, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import * as serviceWorker from "registerServiceWorker.tsx";

import { StorageProperties } from "@libs/constants/storage-properties.ts";
import { useStores } from "@stores/hooks/useStores.ts";

let intervalId: any;
export const ServiceWorkerRegistration: FunctionComponent = () => {
  const { core } = useStores();
  const location = useLocation();
  const hasNewVersion = useRef<boolean>(false);
  const resetRefDataCache = useCallback(() => {
    const preTenantId = sessionStorage.getItem(StorageProperties.tenantId);
    if (
      core.tenantDetails?.id &&
      (!preTenantId || preTenantId !== core.tenantDetails.id)
    ) {
      sessionStorage.setItem(StorageProperties.tenantId, core.tenantDetails.id);

      caches.keys().then(keys => {
        const refDataKey = keys.find(k => k.includes("ref-data"));
        refDataKey && caches.delete(refDataKey);
      });
    }
  }, [core.tenantDetails?.id]);

  const onUpdateServiceWorker = () => {
    hasNewVersion.current = true;
  };

  const onSuccessServiceWorker = (registration: ServiceWorkerRegistration) => {
    hasNewVersion.current = false;

    // for a development purpose we want to check periodically
    // server updates  (30 min)
    if (!intervalId) {
      const THIRTY_MIN_IN_MILLISECONDS = 1800000;

      intervalId = setInterval(
        () => registration.update(),
        THIRTY_MIN_IN_MILLISECONDS
      );
    }
  };

  useEffect(() => {
    // lister to route changes. if we have a new version and a user navigates to
    // a new page update app by screen reloading
    if (hasNewVersion.current) {
      window.location.reload();
    }
  }, [location.pathname]);

  useEffect(() => {
    // we listen to changes in tenantDetails and compare tenant id with
    // a previously saved in sessionStorage. if it is a different of a first login
    // we persis tenantId and check ref-data cache to delete it and fetch now ref
    // data sets for a new tenant
    resetRefDataCache();

    // register service-worker and subscribe its updates
    serviceWorker.register({
      onUpdate: onUpdateServiceWorker,
      onSuccess: onSuccessServiceWorker
    });

    return () => intervalId && clearInterval(intervalId);
  }, [core, resetRefDataCache]);

  return null;
};
