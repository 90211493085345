import { useAppRouter } from "app-shell/app-router/hooks/useAppRouter.tsx";
import { memo } from "react";
import { RouterProvider } from "react-router-dom";

import { CenteredLargeSpinner } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { syncNavigateWithStore } from "./utils/sync-navigate-with-store.util.ts";

export const AppRouter = memo(() => {
  const { routing } = useStores();
  const routes = useAppRouter();
  //Temporary to keep router store location in sync.
  const router = syncNavigateWithStore(routing, routes);
  return (
    <RouterProvider
      router={router}
      fallbackElement={<CenteredLargeSpinner />}
    />
  );
});
