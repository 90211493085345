import { FunctionComponent } from "react";

import {
  DefaultButton,
  Dialog,
  Heading,
  LabeledText,
  LabeledTextGroup,
  Stack
} from "@bps/fluent-ui";
import { appContext } from "@libs/config/app-context.utils.ts";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface AboutDialogProps {
  hidden: boolean;
  onClose?: () => void;
}

export const AboutDialog: FunctionComponent<AboutDialogProps> = ({
  hidden,
  onClose
}) => {
  const { config } = appContext;
  const {
    core,
    core: { location }
  } = useStores();

  const { parentOrgUnit } = location;
  const UNKNOWN = "unknown";
  return (
    <Dialog
      minWidth={600}
      hidden={hidden}
      onDismiss={onClose}
      dialogContentProps={{
        title: <Heading variant="modal-heading">System information</Heading>,
        showCloseButton: true
      }}
    >
      <LabeledTextGroup>
        <LabeledText noSemicolon label="Version">
          {config.version || UNKNOWN}
        </LabeledText>
        <LabeledText noSemicolon label="BP Tenant ID">
          {core.tenantDetails?.id || UNKNOWN}
        </LabeledText>
        <LabeledText noSemicolon label="Business name">
          {parentOrgUnit ? (
            <DataFetcher<OrgUnit>
              fetch={() => core.getOrgUnit(parentOrgUnit.id)}
            >
              {orgUnit => <span>{orgUnit.name}</span>}
            </DataFetcher>
          ) : (
            <span>{UNKNOWN}</span>
          )}
        </LabeledText>
        {config.commitHash && (
          <LabeledText noSemicolon label="Commit hash">
            {config.commitHash}
          </LabeledText>
        )}
      </LabeledTextGroup>
      <Stack
        horizontal
        horizontalAlign="end"
        styles={{ root: { paddingTop: 16 } }}
        tokens={{ childrenGap: 8 }}
      >
        <DefaultButton id="cancelVersion" onClick={onClose} text="Close" />
      </Stack>
    </Dialog>
  );
};
