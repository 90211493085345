/* eslint-disable no-console */
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://vite-pwa-org.netlify.app/

interface Config {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
}

const allowServiceWorker = "serviceWorker" in navigator;

export function register(config: Config) {
  if ("serviceWorker" in navigator) {
    if (import.meta.env.MODE !== "development") {
      // The URL constructor is available in all browsers that support SW.
      const publicUrl = new URL("/", window.location.toString());
      if (publicUrl.origin !== window.location.origin) {
        // Our service worker won't work if PUBLIC_URL is on a different origin
        // from what our page is served on. This might happen if a CDN is used to
        // serve assets; see https://github.com/facebook/create-react-app/issues/2374
        return;
      }

      // Is not localhost. Just register service worker
      registerValidSW(config);
    } else {
      navigator.serviceWorker
        .getRegistration("/service-worker.js")
        .then(reg => reg?.unregister());
    }
  }
}

function registerValidSW(config: Config) {
  //https://vite-pwa-org.netlify.app/guide/service-worker-without-pwa-capabilities.html#registering-of-the-service-worker-in-your-app
  navigator.serviceWorker
    .register("/service-worker.js")
    .then(registration => {
      // Execute onSuccess callback
      if (config && config.onSuccess) {
        config.onSuccess(registration);
      }

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (navigator.serviceWorker.controller) {
            // At this point, the updated precached content has been fetched,
            // but the previous service worker will still serve the older
            // content until all client tabs are closed.
            console.log(
              "New content is available and will be used when all " +
                "tabs for this page are closed. See https://vite-pwa-org.netlify.app"
            );

            // Execute callback
            if (config.onUpdate) {
              config.onUpdate(registration);
            }
          } else {
            // At this point, everything has been precached.
            // It's the perfect time to display a
            // "Content is cached for offline use." message.
            console.log("Content is cached for offline use.");
          }
        };
      };
    })
    .catch(error => {
      console.error("Error during service worker registration:", error);
    });
}

export function unregister() {
  if (allowServiceWorker) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
