import { ConfirmDialog, Stack, Text, useTheme } from "@bps/fluent-ui";

interface UnsavedEndUserSessionDialogProps {
  hidden: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const UnsavedEndUserSessionDialog: React.FC<
  UnsavedEndUserSessionDialogProps
> = ({ hidden, onCancel, onConfirm }) => {
  const { palette } = useTheme();

  return (
    <ConfirmDialog
      hidden={hidden}
      minWidth={480}
      dialogContentProps={{
        title: "You will be signed out in 1 minute",
        onDismiss: onCancel
      }}
      confirmButtonProps={{
        styles: {
          root: {
            display: "true",
            color: palette.black,
            backgroundColor: palette.white,
            borderColor: palette.black
          }
        },

        text: "Sign out & discard changes"
      }}
      onConfirm={onConfirm}
      cancelButtonProps={{
        text: "Close",
        styles: {
          root: {
            borderColor: palette.black
          }
        }
      }}
      onCancel={onCancel}
    >
      <Stack tokens={{ childrenGap: 20 }}>
        <Text>You must reauthenticate.</Text>
        <Text
          styles={{
            root: {
              fontWeight: "900"
            }
          }}
        >
          If you have unsaved changes, these will be lost
        </Text>
      </Stack>
    </ConfirmDialog>
  );
};
