import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import {
  Callout,
  DirectionalHint,
  getId,
  ISearchBox,
  KeyCodes,
  Link,
  mergeStyleSets,
  NoDataTile,
  SearchBox,
  Spinner,
  Stack,
  Suggestions,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { AppointmentSearch } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { CalendarEventStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AppointmentInformationModal } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/appointment-information/AppointmentInformationModal.tsx";
import { extractName } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import {
  MIN_SEARCH_CHAR_DATE_OF_BIRTH,
  MIN_SEARCH_CHAR_PATIENT_NAME,
  MIN_SEARCH_CHAR_PHONE_NUMBER
} from "@modules/practice/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { HotkeyActionNames } from "@stores/user-experience/UserHotkeysHelper.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { LinkButton } from "@ui-components/navigation/LinkButton.tsx";
import { getNavBarStylesSet } from "@ui-components/navigation/NavBar.styles.ts";
import {
  convertSearchValueToBirthdayString,
  hasValidBirthdayData,
  hasValidNameData,
  hasValidPhoneNumberData
} from "@ui-components/pickers/contact-picker/contact-picker.utils.ts";
import { When } from "@ui-components/withPerm.tsx";

import { PatientAppointmentItemFooter } from "./PatientAppointmentItemFooter.tsx";
import { PatientAppointmentSearchPersona } from "./PatientAppointmentSearchPersona.tsx";
import { getPatientSearchBoxStyles } from "./PatientAppointmentsSearch.styles.ts";
import {
  PatientAppointmentsSearchProps,
  PatientEventsData
} from "./PatientAppointmentsSearch.types.ts";
import { RecentSearchesList } from "./RecentSearchesList.tsx";
import { getPatientEventsData } from "./utils.ts";

const searchBoxId = getId("patient-search-viewer");

export const PatientAppointmentsSearch: React.FC<PatientAppointmentsSearchProps> =
  observer(({ collapseMode }) => {
    const { practice, userExperience, core, booking } = useStores();

    const [patientName, setPatientName] = useState("");
    const [active, setActive] = useState(0);
    const [searchPatient, setSearchPatient] = useState("");
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [contactId, setContactId] = useState<string | undefined>(undefined);

    const searchRef = useRef<ISearchBox | null>(null);
    const options = useRef<Contact[]>([]);
    const [recentSearchList, setRecentSearchList] = useState<string[]>([]);
    useEffect(
      () =>
        userExperience.ui.userHotkeysHelper.registerAction(
          HotkeyActionNames.patientSearch,
          () => {
            if (showSearchBox) {
              searchRef.current?.focus();
            } else {
              setShowSearchBox(true);
            }
          }
        ),
      [showSearchBox, userExperience.ui.userHotkeysHelper]
    );

    useEffect(() => {
      if (searchRef && showSearchBox) {
        searchRef.current?.focus();
      }
    }, [showSearchBox]);

    const theme = useTheme();

    const {
      setPatientAppointmentsSearchOnFocus,
      isPatientAppointmentsSearchOnFocus,
      showContactDetails
    } = practice.ui;

    const canCreateNewAppointment = core.hasPermissions(
      Permission.CalendarEventWrite
    );

    const calloutMaxHeight = canCreateNewAppointment ? 640 : 415;

    const CALLOUT_WIDTH = 320;
    const LINK_MAX_HEIGHT = 36;
    const GAP_SPACE = 8;
    const height = calloutMaxHeight - LINK_MAX_HEIGHT;

    const addToRecentSearchList = (patientId: string) => {
      setRecentSearchList([
        patientId,
        ...recentSearchList.filter(x => x !== patientId)
      ]);
      return;
    };

    const { localisedConfig } = userExperience;

    const patientLabelPlural = localisedConfig("patientDisplay", {
      capitalizeFirst: true,
      plural: true
    });

    const patientLabel = localisedConfig("patientDisplay");

    const searchPatientLabel: string = localisedConfig("patientDisplay", {
      plural: true
    });

    const searchPatients = `Search ${searchPatientLabel}`;

    const {
      searchBoxStyle,
      addNewPatientComponentStyles,
      suggestionsContainerStyles
    } = getPatientSearchBoxStyles(theme, showSearchBox, height);

    const { getCommandBarButtonStyle } = getNavBarStylesSet(theme);

    const onNameChanged = (
      event: ChangeEvent<HTMLInputElement>,
      value: string
    ) => {
      setPatientName(value);
    };

    const onDismissCallout = () => {
      setPatientName("");
      setSearchPatient("");
      setShowSearchBox(false);
      setPatientAppointmentsSearchOnFocus(false);
    };

    const onDismissApptInfoModal = () => {
      setContactId(undefined);
    };

    const onFocusHandler = () => {
      setPatientAppointmentsSearchOnFocus(true);
    };

    const onLinkButtonClickHandler = () => {
      setShowSearchBox(true);
      setPatientAppointmentsSearchOnFocus(true);
    };

    const isValidBirthdayDate = hasValidBirthdayData(searchPatient);
    const isValidPhoneNumber = hasValidPhoneNumberData(searchPatient);
    const isValidName = hasValidNameData(searchPatient);
    const birthdayString = convertSearchValueToBirthdayString(searchPatient);

    const fetchPatients = async (): Promise<PatientEventsData[]> => {
      if (searchPatient.length > 2) {
        const patients = await practice.fetchContacts({
          filter: {
            search:
              isValidName && !isValidPhoneNumber ? searchPatient : undefined,
            birthday: isValidBirthdayDate ? birthdayString : undefined,
            phoneNumber: isValidPhoneNumber ? searchPatient : undefined,
            types: [ContactType.Patient],
            statuses: [ContactStatus.Active],
            take: 50,
            skip: 0,
            total: true
          }
        });

        options.current = patients?.results;
        setActive(0);

        const patientIds = patients?.results.map(patient => patient.id);

        if (patientIds.length > 0) {
          const calendarEvents = await booking.getCalendarEvents(
            {
              statuses: [CalendarEventStatus.Confirmed],
              attendees: patientIds
            },
            {
              loadCalendarEventUsers: false,
              loadCalendarEventContacts: false,
              loadCalendarEventExtensions: false,
              baggage: { UiOption: AppointmentSearch.SearchBox }
            }
          );
          return getPatientEventsData(calendarEvents.results, patients.results);
        }
        return [];
      } else {
        return [];
      }
    };

    const isRecentSearchOptionsListShown =
      (isValidName && searchPatient.length < MIN_SEARCH_CHAR_PATIENT_NAME) ||
      (!isValidName &&
        !isValidPhoneNumber &&
        searchPatient.length < MIN_SEARCH_CHAR_DATE_OF_BIRTH) ||
      (isValidPhoneNumber &&
        searchPatient.length < MIN_SEARCH_CHAR_PHONE_NUMBER);

    const isCalloutHidden =
      (isValidName &&
        searchPatient.length < MIN_SEARCH_CHAR_PATIENT_NAME &&
        !recentSearchList.length) ||
      (searchPatient.length === 0 && !recentSearchList.length);

    const renderSecondaryText = (patient: Contact) => (): JSX.Element => {
      return (
        <Stack horizontal tokens={{ childrenGap: 16 }}>
          <Text variant="small">{getDateOfBirth(patient)}</Text>
          <Text variant="small">{getPhoneNumber(patient)}</Text>
        </Stack>
      );
    };

    const getPhoneNumber = (patient: Contact) => {
      if (isValidPhoneNumber) {
        const phone = patient.phoneCommunicationsOnly.find(x =>
          x.value.includes(searchPatient)
        );
        if (phone) {
          return phone.value;
        }
      }
      if (patient.phone) {
        return patient.phone;
      }
      return "No PH recorded";
    };

    const getDateOfBirth = (patient: Contact) => {
      const { birthDate } = patient;
      if (birthDate) {
        return birthDate.toDayDefaultFormat();
      }
      return "No DOB rec.";
    };

    const canAddNewPatient = core.hasPermissions(Permission.PatientWrite);

    const getNoDataText = () => {
      if (isValidPhoneNumber) {
        return `No phone numbers matching "${searchPatient}"`;
      } else if (isValidBirthdayDate) {
        return `No DOB matching "${searchPatient}"`;
      }
      return `No patients matching "${searchPatient}"`;
    };

    const getNoDataLinkText = () => {
      if (isValidPhoneNumber || isValidBirthdayDate) {
        return "Add new patient";
      }
      return `Add "${searchPatient}" as a new ${patientLabel}`;
    };

    const extractPatientName = () => {
      if (!isValidPhoneNumber && !isValidBirthdayDate) {
        return extractName(searchPatient);
      }
      return undefined;
    };

    const onAddNewPatientClickHandler = () => {
      setPatientName("");
      practice.ui.showAddContact(ContactType.Patient, extractPatientName());
      onDismissCallout();
    };

    return (
      <>
        {!showSearchBox && (
          <LinkButton
            text={collapseMode ? searchPatients : patientLabelPlural}
            iconProps={{ iconName: "Search" }}
            styles={getCommandBarButtonStyle()}
            onClick={onLinkButtonClickHandler}
          />
        )}
        {showSearchBox && (
          <>
            <div id={searchBoxId}>
              <SearchBox
                removeSpecialCharacters={false}
                componentRef={ref => {
                  searchRef.current = ref;
                }}
                autoFocus
                onFocus={onFocusHandler}
                value={patientName}
                onChange={onNameChanged}
                onKeyUp={event => {
                  if (showSearchBox) {
                    if (
                      event.code === KeyCodes.down.toString() &&
                      active < options.current.length - 1
                    ) {
                      setActive(prev => prev + 1);
                    }
                    if (event.code === KeyCodes.up.toString() && active > 0) {
                      setActive(prev => prev - 1);
                    }

                    const activeContact = options.current[active];
                    if (
                      event.code === KeyCodes.enter.toString() &&
                      !!activeContact
                    ) {
                      showContactDetails(activeContact.id);
                      onDismissCallout();
                      addToRecentSearchList(activeContact.id);
                    }
                  }
                }}
                onSearch={setSearchPatient}
                iconProps={{ iconName: "Search" }}
                showIcon
                underlined
                styles={mergeStyleSets(searchBoxStyle, {
                  root: { padding: "0" }
                })}
                placeholder={
                  !collapseMode && !isPatientAppointmentsSearchOnFocus
                    ? patientLabelPlural
                    : searchPatients
                }
              />
            </div>
            <Callout
              directionalHint={DirectionalHint.bottomLeftEdge}
              isBeakVisible={false}
              target={`#${searchBoxId}`}
              onDismiss={onDismissCallout}
              finalHeight={calloutMaxHeight}
              hidden={isCalloutHidden}
              calloutWidth={CALLOUT_WIDTH}
              calloutMaxHeight={calloutMaxHeight}
              gapSpace={GAP_SPACE}
              hideOverflow
            >
              {isRecentSearchOptionsListShown ? (
                <RecentSearchesList
                  onDismissCallout={onDismissCallout}
                  searchPatientName={searchPatient}
                  patientIds={recentSearchList}
                  onSelectedPatient={addToRecentSearchList}
                />
              ) : (
                <DataFetcher<PatientEventsData[]>
                  fetch={fetchPatients}
                  refetchId={searchPatient}
                  fallback={
                    <Spinner styles={{ root: { padding: "16px 0px" } }} />
                  }
                >
                  {patients => {
                    return patients.length > 0 ? (
                      <>
                        <Suggestions
                          onSuggestionClick={evt => {
                            evt?.stopPropagation();
                          }}
                          suggestions={patients.map((s, idx) => ({
                            item: s,
                            selected: idx === active
                          }))}
                          styles={suggestionsContainerStyles}
                          onRenderSuggestion={({
                            upcomingAppointment,
                            lastAppointment,
                            patient
                          }) => (
                            <Stack styles={{ root: { width: "100%" } }}>
                              <PatientAppointmentSearchPersona
                                patient={patient}
                                onDismissCallout={onDismissCallout}
                                onRenderSecondaryText={renderSecondaryText(
                                  patient
                                )}
                                onItemClick={() =>
                                  addToRecentSearchList(patient.id)
                                }
                              />
                              <When permission={Permission.CalendarEventWrite}>
                                <PatientAppointmentItemFooter
                                  setShowEditAppointmentModal={patientId => {
                                    setContactId(patientId);
                                    setPatientName(patient.fullName);
                                  }}
                                  lastAppointment={lastAppointment}
                                  nextAppointment={upcomingAppointment}
                                  patient={patient}
                                  onDismissCallout={onDismissCallout}
                                  onAddEventClick={() =>
                                    addToRecentSearchList(patient.id)
                                  }
                                />
                              </When>
                            </Stack>
                          )}
                        />

                        <Stack styles={addNewPatientComponentStyles}>
                          <Link
                            onClick={onAddNewPatientClickHandler}
                            styles={{ root: { padding: 8 } }}
                          >
                            {`New ${patientLabel}`}
                          </Link>
                        </Stack>
                      </>
                    ) : (
                      <NoDataTile
                        textProps={{ text: getNoDataText() }}
                        linkProps={{
                          hidden: !canAddNewPatient,
                          text: getNoDataLinkText(),
                          onClick: onAddNewPatientClickHandler
                        }}
                      />
                    );
                  }}
                </DataFetcher>
              )}
            </Callout>
          </>
        )}
        {contactId && (
          <AppointmentInformationModal
            contactId={contactId}
            isHidden={!contactId}
            onDismissInfoModal={onDismissApptInfoModal}
            patientName={patientName}
          />
        )}
      </>
    );
  });
