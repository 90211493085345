import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { BestPracticeSvgLogo, Heading, Stack, Text } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

const IMAGE_SIZE = 100;

interface InactivityPinFormHeaderProps {
  title: string;
}
export const InactivityPinFormHeader: FunctionComponent<InactivityPinFormHeaderProps> =
  observer(({ title }) => {
    const { core } = useStores();

    return (
      <Stack horizontalAlign="center" styles={{ root: { marginBottom: 32 } }}>
        <BestPracticeSvgLogo
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
          isBlue={true}
        />
        <Heading variant="heading">{title}</Heading>
        <Text>{core.user?.email}</Text>
      </Stack>
    );
  });
