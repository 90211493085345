import { To } from "react-router-dom";

import { IButtonStyles, mergeStyleSets, useTheme } from "@bps/fluent-ui";
import { RoutePermissionOptions } from "@libs/routing/types/route-permission-options.interface.ts";
import { RouteDefinition } from "@stores/router/hooks/useRouteDefinitionsFilter.ts";
import { LinkButton } from "@ui-components/navigation/LinkButton.tsx";
import { getNavBarStylesSet } from "@ui-components/navigation/NavBar.styles.ts";
import { When } from "@ui-components/withPerm.tsx";

interface NavBarItemProps
  extends Pick<RouteDefinition, "businessRoleClasses">,
    RoutePermissionOptions {
  to: To;
  businessRoleClassesOperator?: "or" | "and";
  iconName?: string | undefined;
  hideText?: boolean;
  text: string | undefined;
  styles?: IButtonStyles;
}

export const NavBarItem: React.FC<NavBarItemProps> = ({
  to,
  text,
  iconName,
  permissions,
  permissionsOperator = "and",
  businessRoleClasses,
  businessRoleClassesOperator = "and",
  styles
}) => {
  const theme = useTheme();

  const { getCommandBarButtonStyle } = getNavBarStylesSet(theme);
  return (
    <When
      permission={permissions}
      businessRoleClass={businessRoleClasses}
      permissionOperator={permissionsOperator}
      businessRoleClassOperator={businessRoleClassesOperator}
    >
      <LinkButton
        text={text}
        iconProps={{
          iconName
        }}
        styles={mergeStyleSets(getCommandBarButtonStyle(), styles)}
        to={to}
      />
    </When>
  );
};
