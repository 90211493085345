import { observer } from "mobx-react-lite";
import { stringifyUrl } from "query-string";

import { AppointmentStartingPoints } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Nav, NavLink } from "@ui-components/navigation/Nav.tsx";

export const apptNavLink = (booking: BookingStore) => ({
  name: "New appointment",
  key: "newAppointment",
  permissions: [
    Permission.AppointmentTypeRead,
    Permission.BookingScheduleRead,
    Permission.CalendarEventRead,
    Permission.CalendarEventWrite
  ],
  onClick: () => {
    booking.ui.showCalendarEventDialog(
      {
        type: CalendarEventType.Appointment
      },
      AppointmentStartingPoints.NavBarAddAppointmentButton
    );
  },
  showBorder: true
});

export const getAppointmentNavLinks = ({
  booking,
  routing
}: IRootStore): NavLink[] => [
  apptNavLink(booking),
  { name: "Appointment book", path: routes.calendarEvents.basePath },
  {
    name: "Appointment reminders",
    key: "eventReminders",
    path: routes.calendarEvents.basePath,
    onClick: () =>
      routing.push(
        stringifyUrl({
          url: routes.calendarEvents.basePath.pattern,
          query: {
            [routes.calendarEvents.queryKeys.calendarPage]: "eventReminders"
          }
        })
      )
  },
  {
    name: "Waiting list",
    key: "waitingList",
    path: routes.calendarEvents.basePath,
    onClick: () =>
      routing.push(
        stringifyUrl({
          url: routes.calendarEvents.basePath.pattern,
          query: {
            [routes.calendarEvents.queryKeys.calendarPage]: "waitingList"
          }
        })
      )
  }
];

export const AppointmentNavButton: React.FC = observer(() => {
  const store = useStores();

  return (
    <Nav
      renderAsButton
      buttonProps={{
        text: "Appointments"
      }}
      links={getAppointmentNavLinks(store)}
    />
  );
});
