import { useEffect } from "react";

import { useAuthorisationContext } from "../context/AuthorisationContext.tsx";

/**
 * Activates browser prompt when a user reloads page.
 */
export const useScreenBlocker = () => {
  const context = useAuthorisationContext();

  const beforeUnloadHandler = (evt: BeforeUnloadEvent) => {
    if (!context.willLogout) {
      evt.preventDefault();
      evt.returnValue = `Are you sure you want to close the window with current progress on ${document.title}?`;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadHandler, {
      capture: true
    });
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler, {
        capture: true
      });
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
