import React from "react";

import { Persona, PersonaSize, Stack, useTheme } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { getPatientSearchBoxStyles } from "./PatientAppointmentsSearch.styles.ts";
import { PatientSearchActionButtons } from "./PatientSearchActionButtons.tsx";

interface PatientAppointmentSearchPersonaProps {
  patient: Contact;
  onRenderSecondaryText: (patient: Contact) => JSX.Element;
  onDismissCallout: () => void;
  onItemClick: (patientId: string) => void;
}
export const PatientAppointmentSearchPersona: React.FC<
  PatientAppointmentSearchPersonaProps
> = ({ patient, onRenderSecondaryText, onDismissCallout, onItemClick }) => {
  const { practice } = useStores();
  const { showContactDetails } = practice.ui;

  const theme = useTheme();
  const { personaWithIconsStyles, personaStyles } =
    getPatientSearchBoxStyles(theme);

  return (
    <Stack
      horizontal
      styles={personaWithIconsStyles}
      className="suggestionContainer__persona"
    >
      <Persona
        onClick={() => {
          showContactDetails(patient.id);
          onDismissCallout();
          onItemClick(patient.id);
        }}
        key={patient.id}
        id={patient.id}
        styles={personaStyles}
        imageUrl={patient.profilePictureUrl}
        imageInitials={patient.initials}
        text={patient.name}
        onRenderSecondaryText={() => onRenderSecondaryText(patient)}
        size={PersonaSize.size24}
      />
      <PatientSearchActionButtons
        patient={patient}
        onIconClick={() => onItemClick(patient.id)}
      />
    </Stack>
  );
};
